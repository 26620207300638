import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import DriverMarker from './DriverMarker';
import { Seal } from '../../../interfaces/Map/mapMarkers';

interface ClusteredDriverMarkersProps {
  sealMarkers: Seal[];
}

const ClusteredDriverMarkers: React.FC<ClusteredDriverMarkersProps> = ({
  sealMarkers,
}) => {
  const [markers, setMarkers] = useState<{ [key: string]: google.maps.Marker }>(
    {}
  );
  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;
    return new MarkerClusterer({ map });
  }, [map]);

  const setMarkerRef = useCallback(
    (marker: google.maps.Marker | null, key: string) => {
      setMarkers((prevMarkers) => {
        if ((marker && prevMarkers[key]) || (!marker && !prevMarkers[key]))
          return prevMarkers;
        if (marker) {
          return { ...prevMarkers, [key]: marker };
        } else {
          const { [key]: _, ...newMarkers } = prevMarkers;
          return newMarkers;
        }
      });
    },
    []
  );

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));

    return () => {
      clusterer.clearMarkers();
    };
  }, [clusterer, markers]);

  return (
    <>
      {sealMarkers.map((seal) => (
        <DriverMarker
          key={seal.serial_number}
          position={{
            lat: Number(seal.latitude),
            lng: Number(seal.longitude),
          }}
          mag={2.4}
          name={seal.driver_full_name}
          sealID={seal.serial_number}
          vehicleAlias={seal.vehicle_alias}
          setMarkerRef={setMarkerRef}
        />
      ))}
    </>
  );
};

export default ClusteredDriverMarkers;
