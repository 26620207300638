import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DriverCards from './Components/DriverCards/DriverCards';
import MapComponent from '../../../components/Map/MapComponent';
import { useCookies } from 'react-cookie';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import { MapMarkerResponse } from '../../../interfaces/Map/mapMarkers';
import { getClientMapMarkers } from '../../../services/Api/apiDashboard';

function Dashboard() {
  const isSmallViewport = useMediaQuery('(max-width:1500px)');

  const drawerWidth = isSmallViewport ? '350px' : '425px';
  const [cookies] = useCookies(['user']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [snackbarRetry, setSnackbarRetry] = useState<(() => void) | undefined>(
    undefined
  );

  const [mapMarkers, setMapMarkers] = useState<MapMarkerResponse>({
    seal: [],
    client: [],
  });

  useEffect(() => {
    const fetchMapMarkers = async () => {
      const payload = { company_id: cookies.user.company_id };
      try {
        const response = await getClientMapMarkers(payload);
        setMapMarkers(response.data);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => fetchMapMarkers);
        setSnackbarOpen(true);
      }
    };
    fetchMapMarkers();
  }, [cookies]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
      >
        <MapComponent
          clientMarkers={mapMarkers.client}
          sealMarkers={mapMarkers.seal}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: drawerWidth,
          height: '100vh',
          zIndex: 1,
          overflowY: 'auto',
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
        }}
      >
        <DriverCards />
      </Box>
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        onRetry={snackbarRetry}
      />
    </Box>
  );
}

export default Dashboard;
