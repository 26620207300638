import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  ThemeProvider,
  Box,
  CircularProgress,
  IconButton,
  Grid2,
} from '@mui/material';
import { useCookies } from 'react-cookie';
import modalTheme from '../../../../theme/modalTheme';
import UploadIcon from '../../../../assets/icons/upload-icon';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import { ReactComponent as FileIcon } from '../../../../assets/icons/file-icon.svg';
import SnackbarComponent from '../../../../components/Snackbar/SnackbarComponent';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';
import {
  assignSealToCompany,
  uploadConfirmSealCsv,
  getSealConfirmDummyCSV,
} from '../../../../services/Api/apiSeal';

interface ConfirmSealProps {
  open: boolean;
  onClose: () => void;
  refetchInfo: () => void;
}

const ConfirmSeal: React.FC<ConfirmSealProps> = ({
  open,
  onClose,
  refetchInfo,
}) => {
  const [cookies] = useCookies(['user']);
  const [disableAddButton, setDisableAddButton] = useState(true);
  const [isAddError, setIsAddError] = useState<string | null>(null);
  const [isActivating, setIsActivating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formValues, setFormValues] = useState<{ serial_number: string }>({
    serial_number: '',
  });

  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [parsedData, setParsedData] = useState<unknown[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('error');
  const { t } = useTranslation();

  const handleAddNewSeal = async () => {
    try {
      setIsAddError(null);
      setIsSuccess(false);
      setDisableAddButton(true);
      setIsActivating(true);
      const response = await assignSealToCompany({
        company_id: cookies.user.company_id,
        serial_number: formValues.serial_number,
      });

      setDisableAddButton(false);
      setIsActivating(false);
      if (response.error === null) {
        setIsSuccess(true);
        setFormValues({ serial_number: '' });
        refetchInfo();
      } else {
        setIsAddError(response.message);
      }
    } catch {
      setSnackbarMessage('Error adding new seal:');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    setDisableAddButton(formValues.serial_number.trim() === '');
  }, [formValues.serial_number]);

  useEffect(() => {
    if (open) {
      setFormValues({ serial_number: '' });
      setIsSuccess(false);
      setIsAddError(null);
    }
  }, [open]);

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      handleFile(files[0]);
    }
  };

  const handleFile = (selectedFile: File) => {
    if (
      selectedFile.type === 'text/csv' ||
      selectedFile.name.endsWith('.csv')
    ) {
      setFile(selectedFile);
      setError(null);
      parseCSV(selectedFile);
    } else {
      setError(t('Only CSV files are accepted.'));
      setFile(null);
      setTimeout(() => setError(null), 1500);
    }
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setDragging(false);
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      const droppedFile = files[0];
      if (
        droppedFile.type === 'text/csv' ||
        droppedFile.name.endsWith('.csv')
      ) {
        setFile(droppedFile);
        setError(null);
        parseCSV(droppedFile);
      } else {
        setError(t('Only CSV files are accepted.'));
        setFile(null);
        setTimeout(() => setError(null), 1500);
      }
    }
  };

  const handleUpload = async () => {
    if (parsedData.length > 0) {
      const formData = new FormData();
      formData.append('file', file as Blob);
      formData.append('company_id', cookies.user.company_id);
      setLoading(true);
      try {
        const response = await uploadConfirmSealCsv(formData);
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Seal_Claim_Response.csv');
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
          setSnackbarMessage(
            'File uploaded successfully, check the Download for details'
          );
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          refetchInfo();
          forceClose();
        } else {
          throw new Error(t('Upload failed'));
        }
      } catch {
        setSnackbarMessage('Error Uploading CSV');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const parseCSV = (file: File) => {
    setLoading(true);
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const data = results.data;
        const errors = validateCSV(data);
        setTimeout(() => {
          if (errors.length > 0) {
            setValidationError(errors.join(', '));
          } else {
            setValidationError(null);
            setParsedData(data);
          }
          setLoading(false);
        }, 2000);
      },
      error: (error: { message: React.SetStateAction<string | null> }) => {
        setTimeout(() => {
          setValidationError(error.message);
          setLoading(false);
        }, 2000);
      },
    });
  };

  const validateCSV = (data: unknown[]): string[] => {
    const errors: string[] = [];

    data.forEach((row, index) => {
      if (typeof row === 'object' && row !== null && 'Serial Number' in row) {
        const serialNumber = (row as { 'Serial Number': string })[
          'Serial Number'
        ];
        if (!serialNumber) {
          errors.push(
            `${t('Row')} ${index + 1}: ${t(
              "Missing required field 'Serial Number'"
            )}`
          );
        }
      } else {
        errors.push(`${t('Row')} ${index + 1}: ${t('Invalid row format')}`);
      }
    });

    return errors;
  };

  const forceClose = () => {
    setFile(null);
    setParsedData([]);
    setValidationError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    onClose();
  };

  const handleDownload = async () => {
    try {
      const response = await getSealConfirmDummyCSV();
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'example.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch {
      setSnackbarMessage('Error downloading example CSV');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleClearFile = () => {
    setFile(null);
    setParsedData([]);
    setValidationError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && open) {
        event.preventDefault();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{ paddingBottom: '8px', fontWeight: '500' }}>
          {t('Activate iMSeals')}
        </DialogTitle>
        <Typography variant="subtitle1" fontWeight={375} fontSize={'16px'}>
          {t(
            'Enter the serial number found at the rear of the iMSeal device or upload a CSV file to activate multiple devices.'
          )}
          <span
            onClick={handleDownload}
            style={{
              color: '#86BE02',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            role="button"
          >
            {' '}
            {t('Download')}{' '}
          </span>
          {t('example document.')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Box
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              height:
                file && !error && !validationError && !loading
                  ? '30px'
                  : '168px',
              width: '100%',
              marginTop: '25px',
              padding: '12px',
              outline:
                error || validationError
                  ? '2px solid red'
                  : dragging
                  ? '2px solid #DEFF8F'
                  : '0px',
              cursor: 'pointer',
              backgroundColor: '#F5F5F5',
              borderRadius: '16px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            {!error && !file && (
              <Box
                sx={{
                  height: '26px',
                  width: '26px',
                  backgroundColor: '#DEFF8F',
                  borderRadius: '16px',
                  padding: '16px',
                }}
              >
                <UploadIcon width={24} height={24} fill="#000000" />
              </Box>
            )}
            {error ? (
              <p
                style={{
                  color: 'red',
                  fontSize: '14px',
                  fontFamily: 'Open Sans',
                }}
              >
                {error}
              </p>
            ) : file ? (
              <>
                {parsedData.length === 0 && (
                  <p
                    style={{
                      fontSize: '18px',
                      fontFamily: 'Open Sans',
                      fontWeight: 600,
                    }}
                  >
                    {file.name}
                  </p>
                )}
                {loading && (
                  <CircularProgress sx={{ color: '#B4EE2B' }} size={40} />
                )}
                {parsedData.length > 0 && (
                  <div
                    style={{
                      maxHeight: '200px',
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FileIcon style={{ width: 15, height: 15 }} />
                      <Typography
                        sx={{ fontSize: 14, fontWeight: 600, color: 'black' }}
                      >
                        {file.name}{' '}
                        <span style={{ color: '#C7C7C7' }}>
                          {(file.size / 1024).toFixed(2)} KB
                        </span>
                      </Typography>
                    </div>
                    <IconButton
                      onClick={handleClearFile}
                      sx={{
                        width: 40,
                        height: 40,
                        border: 'none',
                        background: 'none',
                      }}
                    >
                      <TrashIcon style={{ width: 20, height: 20 }} />
                    </IconButton>
                  </div>
                )}
                <div
                  style={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                    marginTop: '10px',
                    width: '100%',
                  }}
                >
                  {validationError && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '14px',
                        fontFamily: 'Open Sans',
                        marginTop: '10px',
                      }}
                    >
                      {validationError}
                    </p>
                  )}
                </div>
              </>
            ) : dragging ? (
              <p style={{ fontSize: '14px', fontFamily: 'Open Sans' }}>
                {t('Release to upload')}
              </p>
            ) : (
              <>
                <Box
                  style={{
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                    textAlign: 'center',
                    paddingTop: '12px',
                  }}
                >
                  <Typography style={{ fontWeight: 600, fontSize: '14px' }}>
                    {t('Drag and drop file to upload')}
                  </Typography>
                  <Typography style={{ fontWeight: 300, fontSize: '14px' }}>
                    {t('or')},{' '}
                    <span
                      onClick={() => fileInputRef.current?.click()}
                      style={{
                        color: '#86BE02',
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }}
                      role="button"
                    >
                      {t('Select File')}
                    </span>{' '}
                    {t('to browse')}.
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
        {!file && (
          <Grid2
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
              marginBottom: '4px',
            }}
          >
            <Typography>OR</Typography>
          </Grid2>
        )}
        {!file && (
          <DialogContent>
            <Typography variant="subtitle2">{t('Serial Number')}*</Typography>
            <TextField
              placeholder="Serial Number"
              type="text"
              value={formValues.serial_number}
              onChange={(e) =>
                setFormValues({ ...formValues, serial_number: e.target.value })
              }
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    boxShadow:
                      '0 0 5px 4px rgba(28, 28, 28, 0.1), 0 0 2px 4px rgba(16, 24, 40, 0.05)',
                  },
                },
              }}
            />
            {isActivating && (
              <Typography variant="h6">{t('Activating')}...</Typography>
            )}
            {isSuccess && (
              <Typography variant="h6" color="#34C759">
                {t('Seal added to company')}.
              </Typography>
            )}
            {isAddError && (
              <Typography variant="h6" color="#d32f2f">
                {isAddError}
              </Typography>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button variant="outlined" onClick={forceClose} color="primary">
            {t('Cancel')}
          </Button>
          {!file ? (
            <Button
              variant="contained"
              disabled={disableAddButton || loading}
              onClick={handleAddNewSeal}
              color="primary"
            >
              {t('Validate Serial Number')}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={!file || !parsedData.length || !!validationError}
              onClick={handleUpload}
            >
              {t('Activate iMSeals')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
    </ThemeProvider>
  );
};

export default ConfirmSeal;
