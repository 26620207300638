import React, { useEffect, useState } from 'react';
import TableComponent from '../../../components/Table/TableComponent';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import useDebounce from '../../../utils/useDebounce';
import { Grid2 } from '@mui/material';
import { CompanyDriversResponse } from '../../../interfaces/Driver/Response/companyDrivers';
import { ReactComponent as TruckIcon } from '../../../assets/icons/truck.svg';
import { ReactComponent as RouteIcon } from '../../../assets/icons/route.svg';
import { ReactComponent as DriverCardIcon } from '../../../assets/icons/address-card.svg';
import { ReactComponent as UnlockIcon } from '../../../assets/icons/unlock.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as UnassignIcon } from '../../../assets/icons/circle-xmark.svg';
import { useCookies } from 'react-cookie';
import AssignToVehicleModal from './Components/AssignToVehicleModal';
import AddDriverModal from './Components/AddDriverModal';
import { CompanyVehiclesResponse } from '../../../interfaces/Vehicles/Response/companyVehicles';
import { DriverDetails } from '../../../types/DriverDetails';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { useNavigate } from 'react-router-dom';
import Locking from '../../../components/Locking/Locking';
import { useTranslation } from 'react-i18next';
import UnassignDriverModal from './Components/UnassignDriverModal';
import {
  getCompanyDrivers,
  updateDriver,
  addNewDriver,
  deleteDriver,
} from '../../../services/Api/apiDriver';
import {
  unassignDriverFromVehicle,
  assignDriverToVehicle,
} from '../../../services/Api/apiRoute';
import { getCompanyVehicles } from '../../../services/Api/apiVehicle';
import { CompanyDriversRequest } from '../../../interfaces/Driver/Request/companyDrivers';
import { CompanyVehiclesRequest } from '../../../interfaces/Vehicles/Request/companyVehicles';
import { generateRandomString } from '../../../utils/generatePassword';

interface EditDriverDetails {
  id: string;
  first_name: string;
  surname: string;
  contact_number: string;
  email: string;
}

function Drivers() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [cookies] = useCookies(['user', 'role']);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const [addDriverFormValues, setAddDriverFormValues] = useState({
    first_name: '',
    surname: '',
    contact_number: '',
    email: '',
  });

  const [assignToVehicleFormValues, setAssignToVehicleFormValues] = useState({
    vehicle: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [snackbarRetry, setSnackbarRetry] = useState<(() => void) | undefined>(
    undefined
  );

  const [drivers, setDrivers] = useState<CompanyDriversResponse[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<DriverDetails | null>(
    null
  );

  const [vehicles, setVehicles] = useState<CompanyVehiclesResponse[]>([]);

  const [openAddDriverModal, setOpenAddDriverModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [driverToDelete, setDriverToDelete] = useState<DriverDetails | null>(
    null
  );

  const columnFieldMapping = {
    'Name': 'full_name',
    'Contact Number': 'contact_number',
    'Email': 'email',
    'Vehicle': 'vehicle_alias',
  };
  const columns = Object.keys(columnFieldMapping);
  const columnWidths = ['20%', '15%', '20%', '20%'];

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'Assign to Vehicle',
      icon: <TruckIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedDriver(row);
        setOpenAssignModal(true);
      },
      disabled: !!row.vehicle_id,
    },
    {
      label: 'Unassign Driver',
      icon: <UnassignIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedDriver(row);
        setOpenUnassignDriver(true);
      },
      disabled: !row.vehicle_id,
    },
    {
      label: 'Edit/Define Route',
      icon: <RouteIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => navigate(`/vehicles/${row.vehicle_id}`),
      disabled: !row.vehicle_id,
    },
    {
      label: 'Edit Driver Details',
      icon: <DriverCardIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        const transformedRow: EditDriverDetails = {
          id: row.id as string,
          first_name: row.first_name as string,
          surname: row.last_name as string,
          contact_number: row.contact_number as string,
          email: row.email as string,
        };
        handleEditDriverClick(transformedRow);
      },
    },
    {
      label: 'Delete Driver',
      icon: <TrashIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setDriverToDelete(row as DriverDetails);
        setOpenDeleteModal(true);
      },
    },
    {
      label:
        row.lock_state === 'LEFT_UNLOCKED'
          ? 'Lock Left'
          : row.lock_state === 'PROCESSING'
          ? 'Processing...'
          : 'Unlock Left',
      icon: (
        <UnlockIcon
          style={{ width: '14px', marginRight: '8px', fill: '#ffffff' }}
        />
      ),
      onClick: () => {
        setOpenLocking(true);
        setLeftLock(true);
        setCurrentLockState(row.lock_state === 'LOCKED');
        setSelectedSeal(row.serial_number as string);
      },
      disabled: !row.seal_id || row.lock_state === 'PROCESSING',
      isLock: !!row.seal_id,
    },
    {
      label:
        row.lock_state === 'RIGHT_UNLOCKED'
          ? 'Lock Right'
          : row.lock_state === 'PROCESSING'
          ? 'Processing...'
          : 'Unlock Right',
      icon: (
        <UnlockIcon
          style={{ width: '14px', marginRight: '8px', fill: '#ffffff' }}
        />
      ),
      onClick: () => {
        setOpenLocking(true);
        setLeftLock(false);
        setCurrentLockState(row.lock_state === 'LOCKED');
        setSelectedSeal(row.serial_number as string);
      },
      disabled: !row.seal_id || row.lock_state === 'PROCESSING',
      isLock: !!row.seal_id,
    },
  ];

  useEffect(() => {
    const fetchDrivers = async () => {
      const payload: CompanyDriversRequest = {
        company_id: cookies.user.company_id,
        search_term: debouncedSearchValue,
        page: 1,
        refetch: false,
      };
      try {
        const data = await getCompanyDrivers(payload);
        setDrivers(data.drivers);
        setHasMore(data.hasMore);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => fetchDrivers);
        setSnackbarOpen(true);
      }
    };

    fetchDrivers();
  }, [debouncedSearchValue, cookies.user.company_id]);

  useEffect(() => {
    const fetchVehicles = async () => {
      const payload: CompanyVehiclesRequest = {
        company_id: cookies.user.company_id,
        search_term: '',
        page: -1,
        refetch: true,
      };
      try {
        const data = await getCompanyVehicles(payload);
        setVehicles(data.vehicles);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => fetchVehicles);
        setSnackbarOpen(true);
      }
    };

    fetchVehicles();
  }, [cookies.user.company_id]);

  const refetchInfo = async () => {
    const payload: CompanyDriversRequest = {
      company_id: cookies.user.company_id,
      search_term: debouncedSearchValue,
      page: page,
      refetch: true,
    };
    try {
      const data = await getCompanyDrivers(payload);
      setDrivers(data.drivers);
      setHasMore(data.hasMore);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarRetry(() => refetchInfo);
      setSnackbarOpen(true);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  const handleAddNewDriver = async () => {
    try {
      const driverData = {
        first_name: addDriverFormValues.first_name,
        last_name: addDriverFormValues.surname,
        contact_number: addDriverFormValues.contact_number,
        email: addDriverFormValues.email,
        company_id: cookies.user.company_id,
        ...(isEditMode ? {} : { password: generateRandomString(20) }),
      };

      let response;
      if (isEditMode) {
        response = await updateDriver({
          ...driverData,
          id: selectedDriver?.id || '',
        });
      } else {
        response = await addNewDriver(driverData);
      }

      if (response.success) {
        setSnackbarMessage(
          isEditMode
            ? 'Driver updated successfully'
            : 'Driver added successfully'
        );
        setSnackbarSeverity('success');
        refetchInfo();
      } else {
        setSnackbarMessage(response.message || 'An error occurred');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      closeAddDriverModal();
      setAddDriverFormValues({
        first_name: '',
        surname: '',
        contact_number: '',
        email: '',
      });
    }
  };

  const [openUnassignDriver, setOpenUnassignDriver] = useState(false);

  const handleUnassignDriver = async () => {
    try {
      const unassignPayload = {
        driver_id: selectedDriver?.id as string,
        vehicle_id: selectedDriver?.vehicle_id as string,
      };

      await unassignDriverFromVehicle(unassignPayload);

      refetchInfo();

      setOpenUnassignDriver(false);
      setSnackbarMessage('Driver Unassigned from Vehicle');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleAssignDriverToVehicle = async () => {
    if (!selectedDriver || !assignToVehicleFormValues.vehicle) {
      setSnackbarMessage('Please select a driver and a vehicle');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const vehicle = vehicles.find(
      (v) => v.vehicle_registration === assignToVehicleFormValues.vehicle
    );

    if (!vehicle) {
      setSnackbarMessage('Selected vehicle is invalid');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await assignDriverToVehicle({
        driver_id: selectedDriver.id!,
        vehicle_id: vehicle.id,
      });

      if (response.statusCode === 200) {
        setSnackbarMessage('Driver assigned to vehicle successfully');
        setSnackbarSeverity('success');

        refetchInfo();

        setOpenAssignModal(false);
      } else {
        setSnackbarMessage(response.message || 'Failed to assign driver');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleDeleteDriver = async () => {
    if (driverToDelete) {
      try {
        const response = await deleteDriver(driverToDelete.id!);
        if (response.success) {
          refetchInfo();

          setSnackbarMessage('Driver deleted successfully');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage('Failed to delete driver');
          setSnackbarSeverity('error');
        }
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        setOpenDeleteModal(false);
      }
    }
  };

  const closeAddDriverModal = () => {
    setOpenAddDriverModal(false);
    setIsEditMode(false);
  };

  const closeAssignModal = () => {
    setOpenAssignModal(false);
  };

  const handlePlusIconClick = () => {
    setOpenAddDriverModal(true);
  };

  const handleEditDriverClick = (driver: EditDriverDetails) => {
    setSelectedDriver(driver);
    setAddDriverFormValues({
      first_name: driver.first_name || '',
      surname: driver.surname || '',
      contact_number: driver.contact_number || '',
      email: driver.email || '',
    });
    setIsEditMode(true);
    setOpenAddDriverModal(true);
  };

  const [openLocking, setOpenLocking] = useState(false);
  const [leftLock, setLeftLock] = useState(false);
  const [currentLockState, setCurrentLockState] = useState(false);
  const [selectedSeal, setSelectedSeal] = useState('');

  const handlePageChange = async () => {
    const payload: CompanyDriversRequest = {
      company_id: cookies.user.company_id,
      search_term: debouncedSearchValue,
      page: page,
      refetch: false,
    };
    try {
      const data = await getCompanyDrivers(payload);
      setDrivers((prevDrivers) => {
        const newDrivers = data.drivers;
        const updatedDrivers = prevDrivers.filter(
          (prevDriver) =>
            !newDrivers.some(
              (newDriver: CompanyDriversResponse) =>
                newDriver.id === prevDriver.id
            )
        );
        return [...updatedDrivers, ...newDrivers];
      });
      setHasMore(data.hasMore);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const setCurrentPage = (page: number) => {
    setPage(page);
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <TableComponent
          title={t('Driver List')}
          onPlusIconClick={handlePlusIconClick}
          onSearchValueChange={handleSearchChange}
          searchValue={searchValue}
          columns={columns}
          data={drivers}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          columnWidths={columnWidths}
          onPageChange={handlePageChange}
          setCurrentPage={setCurrentPage}
          hasMore={hasMore}
        />
      </Grid2>

      <AddDriverModal
        open={openAddDriverModal}
        onClose={closeAddDriverModal}
        onSubmit={handleAddNewDriver}
        formValues={addDriverFormValues}
        setFormValues={setAddDriverFormValues}
        isEditMode={isEditMode}
      />

      <UnassignDriverModal
        open={openUnassignDriver}
        onClose={() => setOpenUnassignDriver(false)}
        onSubmit={handleUnassignDriver}
      />

      <AssignToVehicleModal
        open={openAssignModal}
        onClose={closeAssignModal}
        driverDetails={selectedDriver || {}}
        vehicles={vehicles}
        onSubmit={handleAssignDriverToVehicle}
        formValues={assignToVehicleFormValues}
        setFormValues={setAssignToVehicleFormValues}
      />

      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={handleDeleteDriver}
        type="Driver"
        name={driverToDelete?.full_name || ''}
      />

      <Locking
        open={openLocking}
        onClose={() => {
          setOpenLocking(false);
          refetchInfo();
        }}
        unlockLeft={leftLock}
        currentState={currentLockState}
        serial_number={selectedSeal}
        company_id={cookies.user.company_id}
      />
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        onRetry={snackbarRetry}
      />
    </Grid2>
  );
}

export default Drivers;
