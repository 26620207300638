import { AxiosError } from 'axios';
import { AddNewUserRequest } from '../../interfaces/Users/Request/addNewUser';
import { EditUserRequest } from '../../interfaces/Users/Request/editUser';
import { GetCompanyUsersRequest } from '../../interfaces/Users/Request/getCompanyUsers';
import axiosInstance from '../../utils/axiosInstance';

export const addNewUser = async (data: AddNewUserRequest) => {
  try {
    const response = await axiosInstance.post(`/user/create-user`, data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const editUser = async (data: EditUserRequest) => {
  try {
    const response = await axiosInstance.put(`/user/update-user`, data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const getCompanyUsers = async (data: GetCompanyUsersRequest) => {
  try {
    const response = await axiosInstance.post('/user/get-user', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const deleteUser = async (id: string) => {
  try {
    await axiosInstance.delete(`/user/delete-user`, {
      params: { id },
    });
    return { success: true };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};
