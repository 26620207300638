import { AxiosError } from 'axios';
import { GetDriverRouteRequest } from '../../interfaces/Driver/Request/getDriverRoute';
import { AddNewVehicleRequest } from '../../interfaces/Vehicles/Request/addNewVehicle';
import { CompanyVehiclesRequest } from '../../interfaces/Vehicles/Request/companyVehicles';
import { EditVehicleRequest } from '../../interfaces/Vehicles/Request/editVehicle';
import axiosInstance from '../../utils/axiosInstance';

export const getCompanyVehicles = async (data: CompanyVehiclesRequest) => {
  try {
    const response = await axiosInstance.post('/vehicle/get-vehicle', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const addNewVehicle = async (data: AddNewVehicleRequest) => {
  try {
    const response = await axiosInstance.post('/vehicle/create-vehicle', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const editVehicle = async (data: EditVehicleRequest) => {
  try {
    const response = await axiosInstance.put('/vehicle/update-vehicle', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const deleteVehicle = async (id: string) => {
  try {
    await axiosInstance.delete(`/vehicle/delete-vehicle`, {
      params: { id },
    });
    return { success: true };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const getDriverRoute = async (data: GetDriverRouteRequest) => {
  try {
    const response = await axiosInstance.post(
      '/vehicle/get-vehicle-route',
      data
    );
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};
