import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Drawer,
  Slide,
  Typography,
  useMediaQuery,
  //   IconButton,
  //   Icon,
} from '@mui/material';
import { useCookies } from 'react-cookie';
import { io, Socket } from 'socket.io-client';
import env from 'react-dotenv';
import EmergencyModal from './EmergencyModal';
import { getDriverId, setDriverId } from '../../utils/expandDriverCard';
import { ReactComponent as BellIcon } from '../../assets/icons/bell-icon.svg';
import { ReactComponent as CollapseIcon } from '../../assets/icons/collapse.svg';
// import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';
// import { ReactComponent as ChevronUp } from '../../assets/icons/chevron-up.svg';
// import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh.svg';
import { ReactComponent as BellDotIcon } from '../../assets/icons/bell-with-dot.svg';
import Lottie from 'lottie-react';
import openNoDot from './NotificationAnimations/OpenNoDot.json';
import closeNoDot from './NotificationAnimations/CloseNoDot.json';
import closeDot from './NotificationAnimations/CloseDot.json';
import openDot from './NotificationAnimations/OpenDot.json';
import firstDot from './NotificationAnimations/DotFirst.json';
import nthDot from './NotificationAnimations/NthDot.json';
import NotificationTemplate from './NotificationTemplate';
import { keyframes } from '@emotion/react';

const API_BASE_URL = env.REACT_APP_API_BASE_URL;

interface EmergencyMessage {
  id: string;
  text: string;
  timestamp?: string;
  highlighted?: boolean;
  details: {
    contact_number: string;
    vehicle_alias: string;
    driver_id: string;
    driver_name: string;
    latitude: string;
    longitude: string;
  };
}

interface NotificationMessage {
  id: string;
  text: string;
  timestamp?: string;
  details: {
    contact_number: string;
    vehicle_alias: string;
    driver_id: string;
    driver_name: string;
    latitude: string;
    longitude: string;
    serial_number: string;
    seal_id: string;
    company_id: string;
    unlock_left: boolean;
    route_id?: string;
    trip_id?: string;
    reAdd?: boolean;
  };
}

const ripple = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  20% {
    transform: scale(1.05);
    opacity: 0.75;
  }
  40% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  60% {
    transform: scale(1.15);
    opacity: 0.25;
  }
  80% {
    transform: scale(1.2);
    opacity: 0.1;
  }
  100% {
    transform: scale(1.25);
    opacity: 0;
  }
`;

const NotificationManager: React.FC = () => {
  const [emergencyMessages, setEmergencyMessages] = useState<
    EmergencyMessage[]
  >([]);
  const [notifications, setNotifications] = useState<NotificationMessage[]>([]);
  const [cookies] = useCookies(['user', 'super_user', 'token']);
  const [, setSocket] = useState<Socket | null>(null);
  const isSmallViewport = useMediaQuery('(max-width:1500px)');
  const drawerWidth = isSmallViewport ? '70px' : '90px';
  const containerRef = useRef<HTMLDivElement>(null);
  const drawerRef = useRef<HTMLDivElement>(null);
  const closeRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [currentAnimation, setCurrentAnimation] = useState<any>(null);
  const [prevNotificationsLength, setPrevNotificationsLength] = useState(
    notifications.length
  );

  const handleIconClick = () => {
    if (drawerOpen) {
      if (notifications.length > 0) {
        setCurrentAnimation(closeDot);
      } else {
        setCurrentAnimation(closeNoDot);
      }
      setDrawerOpen(false);
    } else {
      if (notifications.length > 0) {
        setCurrentAnimation(openDot);
      } else {
        setCurrentAnimation(openNoDot);
      }
      setDrawerOpen(true);
    }
  };

  useEffect(() => {
    if (
      prevNotificationsLength === 0 &&
      notifications.length === 1 &&
      !drawerOpen
    ) {
      setCurrentAnimation(firstDot);
    } else if (prevNotificationsLength < notifications.length && !drawerOpen) {
      setCurrentAnimation(nthDot);
    }
    setPrevNotificationsLength(notifications.length);
  }, [notifications, drawerOpen]);

  useEffect(() => {
    let timeoutDuration = 1250;

    switch (currentAnimation) {
      case firstDot:
        timeoutDuration = 3270;
        break;
      case nthDot:
        timeoutDuration = 3270;
        break;
      default:
        timeoutDuration = 1250;
    }

    if (currentAnimation) {
      const timer = setTimeout(
        () => setCurrentAnimation(null),
        timeoutDuration
      );
      return () => clearTimeout(timer);
    }
  }, [currentAnimation]);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (cookies.user) {
      const userData = cookies.user;
      if (userData.id) {
        const newSocket = io(API_BASE_URL, {
          query: { userId: userData.id },
          path: '/api/socket.io',
        });
        setSocket(newSocket);
        newSocket.on(
          'emergency',
          (message: {
            id: string;
            text: string;
            timestamp: string;
            information: {
              contact_number: string;
              vehicle_alias: string;
              driver_id: string;
              driver_name: string;
              latitude: string;
              longitude: string;
            };
          }) => {
            setEmergencyMessages((prevMessages) => {
              const existingMessageIndex = prevMessages.findIndex(
                (msg) => msg.details.driver_id === message.information.driver_id
              );

              if (existingMessageIndex !== -1) {
                const updatedMessages = [...prevMessages];
                updatedMessages[existingMessageIndex] = {
                  ...updatedMessages[existingMessageIndex],
                  text: message.text,
                  details: message.information,
                  highlighted: true,
                };

                setTimeout(() => {
                  updatedMessages[existingMessageIndex].highlighted = false;
                  setEmergencyMessages([...updatedMessages]);
                }, 2000);

                return updatedMessages;
              }

              return [
                ...prevMessages,
                {
                  id: message.id,
                  text: message.text,
                  details: message.information,
                  highlighted: false,
                },
              ];
            });
          }
        );

        newSocket.on('requestunlock', (message) => {
          setNotifications((prevRequests) => [
            ...prevRequests,
            {
              id: message.id,
              text: message.text,
              details: message.information,
            },
          ]);
        });

        newSocket.on('requestlock', (message) => {
          setNotifications((prevRequests) => [
            ...prevRequests,
            {
              id: message.id,
              text: message.text,
              details: message.information,
            },
          ]);
        });

        newSocket.on('skiprequest', (message) => {
          setNotifications((prevRequests) => [
            ...prevRequests,
            {
              id: message.id,
              text: message.text,
              details: message.information,
            },
          ]);
        });

        newSocket.on('lockfeedback', (message) => {
          setNotifications((prevRequests) => [
            ...prevRequests,
            {
              id: message.id,
              text: message.text,
              details: message.information,
            },
          ]);
        });

        newSocket.on('cradlelockfeedback', (message) => {
          setNotifications((prevRequests) => [
            ...prevRequests,
            {
              id: message.id,
              text: message.text,
              details: message.information,
            },
          ]);
        });

        return () => {
          newSocket.off('emergency');
          newSocket.off('requestunlock');
          newSocket.off('skiprequest');
          newSocket.off('lockfeedback');
          newSocket.off('requestlock');
          newSocket.off('cradlelockfeedback');
          newSocket.close();
        };
      }
    }
  }, [cookies.user]);

  useEffect(() => {
    if (lastMessageRef.current) {
      const timer = setTimeout(() => {
        if (lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [emergencyMessages]);

  const removeMessage = (index: number, id: string) => {
    setEmergencyMessages((prevMessages) =>
      prevMessages.filter((_, i) => i !== index)
    );
    if (getDriverId() === id) {
      setDriverId('');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node) &&
        closeRef.current &&
        !closeRef.current.contains(event.target as Node)
      ) {
        setDrawerOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 1300,
        width: 'calc(100vw)',
        maxWidth: `calc(100vw - ${drawerWidth})`,
        display: 'flex',
        flexDirection: 'row-reverse',
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          zIndex: 1400,
        }}
      >
        <Box
          onClick={handleIconClick}
          sx={{
            top: 0,
            right: 0,
            zIndex: 1400,
            height: '60px',
            width: '160px',
            visibility: 'visible',
            transform: drawerOpen ? 'translateX(-400px)' : 'translateX(0)',
            transition: drawerOpen
              ? 'transform 0.3s ease-in-out'
              : 'transform 0.2s ease-in-out',
          }}
        >
          {currentAnimation ? (
            <Lottie
              animationData={currentAnimation}
              loop={true}
              autoplay={true}
              rendererSettings={{
                preserveAspectRatio: 'xMidYMid slice',
              }}
            />
          ) : (
            <Box
              ref={closeRef}
              style={{
                backgroundColor: 'white',
                borderTopLeftRadius: '20px',
                borderBottomLeftRadius: '20px',
                width: '75px',
                height: '73px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 'auto',
                marginTop: '12px',
                boxShadow: '-5px 0px 5px 0px rgba(0, 0, 0, 0.03)',
              }}
            >
              {drawerOpen ? (
                <CollapseIcon
                  style={{
                    marginRight: '8.5px',
                    width: 34,
                    height: 34,
                  }}
                />
              ) : notifications.length > 0 ? (
                <BellDotIcon
                  style={{
                    marginBottom: '4.5px',
                    marginLeft: '2.5px',
                    width: 32,
                    height: 32,
                  }}
                />
              ) : (
                <BellIcon
                  style={{
                    width: 28,
                    height: 28,
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        hideBackdrop
        PaperProps={{
          sx: { width: 400, boxShadow: '-10px -10px 50px 0px #0000001A' },
        }}
      >
        <Box
          sx={{
            height: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px 8px 0 8px',
          }}
        ></Box>
        <Box sx={{ padding: '0 24px 10px 24px' }}>
          <Typography>Alerts</Typography>
        </Box>
        <Box ref={drawerRef} sx={{ overflowY: 'auto' }}>
          {notifications.map((notification, index) => {
            const uniqueKey = `${notification.details.seal_id}-${index}`;
            return (
              <NotificationTemplate
                key={uniqueKey}
                uniqueKey={uniqueKey}
                text={notification.text}
                details={notification.details}
                onClose={(uniqueKey: string) =>
                  setNotifications((prev) =>
                    prev.filter(
                      (_, i) =>
                        `${notification.details.seal_id}-${i}` !== uniqueKey
                    )
                  )
                }
              />
            );
          })}
        </Box>
      </Drawer>
      {emergencyMessages.map((message, index) => (
        <Slide
          key={message.id}
          direction="left"
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 1000, exit: 300 }}
          easing={{
            enter: 'cubic-bezier(0.4, 0, 0.2, 1)',
            exit: 'cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        >
          <Box
            ref={index === emergencyMessages.length - 1 ? lastMessageRef : null}
            tabIndex={-1}
            sx={{
              marginRight: 2,
              marginTop: 1,
              zIndex: 1500,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'auto',
              marginBottom: 2.5,
              height: 'auto',
              backgroundColor: '#17b87c',
              borderRadius: 4,
              ...(message.highlighted && {
                '&:before, &:after': {
                  position: 'absolute',
                  content: '""',
                  top: '-3px',
                  right: '-3px',
                  bottom: '-3px',
                  left: '-3px',
                  border: 'solid 6px rgba(186, 31, 31, 0.5)',
                  borderRadius: 4,
                },
                '&:before': {
                  animation: `${ripple} 1s linear 0s infinite`,
                },
                '&:after': {
                  animation: `${ripple} 1s linear 0.2s infinite`,
                },
              }),
            }}
          >
            <EmergencyModal
              message={message.text}
              onClose={() => removeMessage(index, message.details.driver_id)}
              details={message.details}
            />
          </Box>
        </Slide>
      ))}
    </Box>
  );
};

export default NotificationManager;
