import { AxiosError } from 'axios';
import { AssignDriverRequest } from '../../interfaces/Driver/Request/assignDriverRequest';
import { AddNewTripRequest } from '../../interfaces/Route/Request/addNewTrip';
import { CancelAddressRequest } from '../../interfaces/Route/Request/cancelAddress';
import { SkipAddressRequest } from '../../interfaces/Route/Request/skipAddress';
import { UnassignDriverFromVehicleRequest } from '../../interfaces/Route/Request/unassignDriverFromVehicle';
import axiosInstance from '../../utils/axiosInstance';

export const assignDriverToVehicle = async (data: AssignDriverRequest) => {
  try {
    const response = await axiosInstance.put(
      '/route/assign-driver-to-vehicle',
      data
    );

    return {
      statusCode: response.data.statusCode,
      data: response.data.data,
      message: response.data.message,
      error: null,
    };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const skipAddress = async (data: SkipAddressRequest) => {
  try {
    const response = await axiosInstance.put('/route/skip-trip', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const cancelAddress = async (data: CancelAddressRequest) => {
  try {
    const response = await axiosInstance.put('/route/cancel-trip', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const addToRoute = async (data: AddNewTripRequest) => {
  try {
    const response = await axiosInstance.post('/route/add-to-route', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const unassignDriverFromVehicle = async (
  data: UnassignDriverFromVehicleRequest
) => {
  try {
    const response = await axiosInstance.put(
      '/route/unassign-driver-from-vehicle',
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};
