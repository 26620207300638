import React from 'react';
import { Box, Grid2, Typography } from '@mui/material';
import headset from '../../assets/icons/headset.svg';
import envelope from '../../assets/icons/envelope.svg';
import { t } from 'i18next';

const Support = () => {
  const sendEmail = () => {
    const body = encodeURIComponent(
      `Hi GritCargo Support Team,
          
          I am experiencing an issue with your services and would appreciate your assistance in resolving it. Could you please provide support at your earliest convenience?
          
          Best regards,
          [User Name]
          [Company Name]
          [Contact Information]`
    );

    window.location.href = `mailto:support@47660588.hubspot-inbox.com?subject=Request for Support&body=${body}`;
  };
  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', justifyContent: 'center', flexDirection: 'column' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: `${(27 / 1080) * 100}vh`,
          marginTop: `${(232.5 / 1080) * 100}vh`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: `${(10 / 1080) * 100}vh`,
          }}
        >
          <Typography
            sx={{
              color: 'var(--black-100, #000)',
              textAlign: 'center',
              fontFeatureSettings: "'ss01' on, 'cv01' on",
              fontFamily: '"Open Sans", sans-serif',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: `${(48 / 1080) * 100}vh`,
              lineHeight: `${(58 / 1080) * 100}vh`,
            }}
          >
            {t('Need help?')}
          </Typography>
          <Typography
            sx={{
              color: 'var(--black-40, #838383)',
              textAlign: 'center',
              fontFeatureSettings: "'ss01' on, 'cv01' on",
              fontFamily: '"Open Sans", sans-serif',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: `${(16 / 1080) * 100}vh`,
              lineHeight: `${(24 / 1080) * 100}vh`,
            }}
          >
            {t('We are here for you.')}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={headset} alt="grit logo" />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: `${(70 / 1080) * 100}vh`,
          gap: `${(30 / 1080) * 100}vh`,
          alignSelf: 'stretch',
        }}
      >
        <Typography
          sx={{
            color: 'var(--black-100, #000)',
            textAlign: 'center',
            fontFeatureSettings: "'ss01' on, 'cv01' on",
            fontFamily: '"Open Sans", sans-serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: `${(24 / 1080) * 100}vh`,
            lineHeight: `${(32 / 1080) * 100}vh`,
          }}
        >
          {t('Contact Support')}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            width: `${(400 / 1920) * 100}vw`,
            minWidth: `${(200 / 1920) * 100}vw`,
            maxWidth: `${(400 / 1920) * 100}vw`,
            padding: `${(24 / 1080) * 100}vh`,
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: `${(10 / 1080) * 100}vh`,
            borderRadius: 'var(--16, 16px)',
            border: '2px solid var(--black-5, #EEE)',
            background: 'var(--Background-BG1, #FFF)',
            boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.05)',
            cursor: 'pointer',
          }}
          onClick={sendEmail}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              alignSelf: 'stretch',
            }}
          >
            <Box
              sx={{
                gap: `${(8 / 1080) * 100}vh`,
                display: 'flex',
                alignItems: 'center',
                flex: '1 0 0',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  padding: 'var(--16, 1.48vh)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 'var(--8, 0.74vh)',
                  borderRadius: 'var(--16, 1.48vh)',
                }}
              >
                <img src={envelope} />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  height: '5.19vh',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flex: '1 0 0',
                  borderRadius: 'var(--8, 0.74vh)',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Primary-Black, #000)',
                    fontFeatureSettings: "'ss01' on, 'cv01' on",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: '1.3vh',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '1.85vh',
                    alignSelf: 'stretch',
                  }}
                >
                  {t('Send an email')}
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--black-40, #838383)',
                    fontFeatureSettings: "'ss01' on, 'cv01' on",
                    fontFamily: 'var(--Font-family-Inter, Inter)',
                    fontSize: '1.3vh',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '1.85vh',
                  }}
                >
                  support@47660588.hubspot-inbox.com
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid2>
  );
};

export default Support;
