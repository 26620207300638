import React, { useEffect, useState } from 'react';
import modalTheme from '../../../../theme/modalTheme';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { GetAllClientsResponse } from '../../../../interfaces/Company/Response/getAllClients';

interface ChangeStatusModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (newStatus: string) => void;
  clientData: GetAllClientsResponse | null;
  formValues: {
    name: string;
    contact_first_name: string;
    contact_last_name: string;
    contact_number: string;
    email: string;
    status: string;
  };
  setFormValues: React.Dispatch<
    React.SetStateAction<{
      name: string;
      contact_first_name: string;
      contact_last_name: string;
      contact_number: string;
      email: string;
      status: string;
    }>
  >;
}

const ChangeStatusModal: React.FC<ChangeStatusModalProps> = ({
  open,
  onClose,
  onConfirm,
  clientData,
  formValues,
  setFormValues,
}) => {
  const statusMapping: { [key: string]: string } = {
    ACTIVE: 'Active',
    PAUSED: 'Paused',
    CANCELLED: 'Cancelled',
    SUSPENDED: 'Suspended',
  };

  const initialStatus = clientData
    ? statusMapping[clientData.account_status] || 'Active'
    : 'Active';

  const [selectedStatus, setSelectedStatus] = useState(initialStatus);

  useEffect(() => {
    if (clientData) {
      const currentStatus =
        statusMapping[clientData.account_status] || 'Active';
      setSelectedStatus(currentStatus);
      setFormValues({ ...formValues, status: currentStatus });
    }
    // eslint-disable-next-line
  }, [clientData, setFormValues]);

  useEffect(() => {
    if (!open) {
      setSelectedStatus(initialStatus);
    }
  }, [open, initialStatus, setFormValues]);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const newStatus = event.target.value;
    setSelectedStatus(newStatus);
    setFormValues({ ...formValues, status: newStatus });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && open) {
      event.preventDefault();
      onConfirm(selectedStatus);
    }
  };

  if (!clientData) return null;

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose} onKeyDown={handleKeyDown}>
        <DialogTitle>Edit Account Status</DialogTitle>
        <Typography
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontWeight: 400,
            padding: '4px 26px 0px 26px',
          }}
        >
          You are about to changes {clientData.client}&apos;s account status.
          Removing Active status will lock all associated users out of the
          system.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '40px',
            alignSelf: 'stretch',
            p: '24px',
            borderRadius: '20px',
            backgroundColor: '#FBFBFB',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <FormControl fullWidth>
            <Typography
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: 600,
                marginBottom: '6px',
              }}
            >
              Update status
            </Typography>
            <Select value={selectedStatus} onChange={handleStatusChange}>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Paused">Paused</MenuItem>
              <MenuItem value="Cancelled">Cancelled</MenuItem>
              <MenuItem value="Suspended">Suspended</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => onConfirm(selectedStatus)}
            variant="contained"
            color="primary"
          >
            Update Account Status
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ChangeStatusModal;
