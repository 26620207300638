import React, { useEffect, useState } from 'react';
import TableComponent from '../../../components/Table/TableComponent';
import useDebounce from '../../../utils/useDebounce';
import { Grid2 } from '@mui/material';

import { ReactComponent as EditIcon } from '../../../assets/icons/warehouse.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { GetCompanyUsersResponse } from '../../../interfaces/Users/Response/getCompanyUsers';
import { GetCompanyUsersRequest } from '../../../interfaces/Users/Request/getCompanyUsers';
import { useCookies } from 'react-cookie';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import { UserDetails } from '../../../types/UserDetails';
import UserModal from './Components/UserModal';
import { useTranslation } from 'react-i18next';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import {
  getCompanyUsers,
  addNewUser,
  editUser,
  deleteUser,
} from '../../../services/Api/apiUser';
import { generateRandomString } from '../../../utils/generatePassword';

interface EditUserDetails {
  role: string;
  id: string;
  first_name: string;
  surname: string;
  contact_number: string;
  email: string;
}

function Users() {
  const [cookies] = useCookies(['user', 'role', 'super_user']);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [selectedUser, setSelectedUser] = useState<UserDetails | null>(null);
  const [users, setUsers] = useState<GetCompanyUsersResponse[]>([]);

  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const [openUserModal, setOpenUserModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [snackbarRetry, setSnackbarRetry] = useState<(() => void) | undefined>(
    undefined
  );

  const { t } = useTranslation();

  const columnFieldMapping = {
    'User Name': 'first_name',
    'User Surname': 'last_name',
    'Contact Number': 'contact_number',
    'Email Address': 'email',
    'Role': 'role',
  };

  const columns = Object.keys(columnFieldMapping);

  const columnWidths = ['10%', '10%', '10%', '35%', '35%'];

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'Edit Details',
      icon: <EditIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        const transformedRow: EditUserDetails = {
          id: row.id as string,
          role: row.role as string,
          first_name: row.first_name as string,
          surname: row.last_name as string,
          contact_number: row.contact_number as string,
          email: row.email as string,
        };
        handleEditUserClick(transformedRow);
      },
      disabled: cookies.role !== 'ADMIN' && !cookies.super_user,
    },
    {
      label: 'Delete User',
      icon: <TrashIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedUser(row as unknown as UserDetails);
        setOpenDeleteModal(true);
      },
      disabled: cookies.role !== 'ADMIN' && !cookies.super_user,
    },
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const payload: GetCompanyUsersRequest = {
        company_id: cookies.user.company_id,
        search_term: debouncedSearchValue,
        page: 1,
        refetch: false,
      };
      try {
        const data = await getCompanyUsers(payload);
        setUsers(data.users);
        setHasMore(data.hasMore);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarRetry(() => fetchUsers);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchUsers();
  }, [debouncedSearchValue, cookies.user.company_id]);

  const [formValues, setFormValues] = useState<{
    role: string;
    first_name: string;
    last_name: string;
    email: string;
    contact_number: string;
  }>({
    role: '',
    first_name: '',
    last_name: '',
    email: '',
    contact_number: '',
  });

  const handlePlusIconClick = () => {
    if (cookies.role === 'ADMIN' || cookies.super_user) {
      setOpenUserModal(true);
    }
  };

  const handleEditUserClick = (driver: EditUserDetails) => {
    setSelectedUser(driver);
    setFormValues({
      role: driver.role || '',
      first_name: driver.first_name || '',
      last_name: driver.surname || '',
      contact_number: driver.contact_number || '',
      email: driver.email || '',
    });
    setIsEditMode(true);
    setOpenUserModal(true);
  };

  const refetchInfo = async () => {
    const payload: GetCompanyUsersRequest = {
      company_id: cookies.user.company_id,
      search_term: debouncedSearchValue,
      page: page,
      refetch: true,
    };
    try {
      const data = await getCompanyUsers(payload);
      setUsers(data.users);
      setHasMore(data.hasMore);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarRetry(() => refetchInfo);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleAddNewUser = async () => {
    try {
      const response = await addNewUser({
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        email: formValues.email,
        contact_number: formValues.contact_number,
        company_id: cookies.user.company_id,
        role: formValues.role,
        password: generateRandomString(20),
      });

      if (response.error === null) {
        setOpenUserModal(false);
        setFormValues({
          ...formValues,
          first_name: '',
          last_name: '',
          email: '',
          contact_number: '',
          role: '',
        });

        refetchInfo();

        setSnackbarMessage('User added successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Failed to add user: User email already exists');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditUser = async () => {
    try {
      const response = await editUser({
        id: selectedUser?.id || '',
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        email: formValues.email,
        contact_number: formValues.contact_number,
        company_id: cookies.user.company_id,
        role: formValues.role,
      });

      if (response.error === null) {
        setOpenUserModal(false);
        setFormValues({
          ...formValues,
          first_name: '',
          last_name: '',
          email: '',
          contact_number: '',
        });

        refetchInfo();

        setSnackbarMessage('User updated successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Failed to update user');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const closeUserrModal = () => {
    setOpenUserModal(false);
    setIsEditMode(false);
  };

  const onSubmit = () => {
    if (isEditMode) {
      handleEditUser();
    } else {
      handleAddNewUser();
    }
  };

  const handleDeleteUser = async () => {
    try {
      const response = await deleteUser(selectedUser!.id!);

      if (response.success) {
        refetchInfo();
        setSnackbarMessage('User deleted successfully');
        setSnackbarSeverity('success');
        setOpenDeleteModal(false);
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Failed to delete user');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handlePageChange = async () => {
    const payload: GetCompanyUsersRequest = {
      company_id: cookies.user.company_id,
      search_term: debouncedSearchValue,
      page: page,
      refetch: false,
    };
    try {
      const data = await getCompanyUsers(payload);
      setUsers((prevUsers) => {
        const newUsers = data.users;
        const updatedUsers = prevUsers.filter(
          (prevUser) =>
            !newUsers.some(
              (newUser: GetCompanyUsersResponse) => newUser.id === prevUser.id
            )
        );
        return [...updatedUsers, ...newUsers];
      });
      setHasMore(data.hasMore);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const setCurrentPage = (page: number) => {
    setPage(page);
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <TableComponent
          title={t('Users')}
          onPlusIconClick={handlePlusIconClick}
          onSearchValueChange={handleSearchChange}
          searchValue={searchValue}
          columns={columns}
          data={users}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          columnWidths={columnWidths}
          onPageChange={handlePageChange}
          setCurrentPage={setCurrentPage}
          hasMore={hasMore}
        />
      </Grid2>

      <UserModal
        open={openUserModal}
        onClose={closeUserrModal}
        isEditMode={isEditMode}
        formValues={formValues}
        setFormValues={setFormValues}
        onSubmit={onSubmit}
      />

      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={handleDeleteUser}
        type="User"
        name={selectedUser?.first_name || ''}
      />

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        onRetry={snackbarRetry}
      />
    </Grid2>
  );
}

export default Users;
