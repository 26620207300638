import { AxiosError } from 'axios';
import { CreateOperatorRequest } from '../../interfaces/Operators/Request/CreateOperator';
import { GetCompanyOperatorsRequest } from '../../interfaces/Operators/Request/GetCompanyOperators';
import { UpdateOperatorRequest } from '../../interfaces/Operators/Request/UpdateOperator';
import axiosInstance from '../../utils/axiosInstance';

export const getCompanyOperators = async (data: GetCompanyOperatorsRequest) => {
  try {
    const response = await axiosInstance.post('/operator/get-operator', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const createCompanyOperator = async (data: CreateOperatorRequest) => {
  try {
    const response = await axiosInstance.post(
      '/operator/create-operator',
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const updateCompanyOperator = async (data: UpdateOperatorRequest) => {
  try {
    const response = await axiosInstance.put('/operator/update-operator', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const deleteCompanyOperator = async (id: string) => {
  try {
    await axiosInstance.delete(`/operator/delete-operator`, {
      params: { id },
    });
    return { success: true };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};
