import React, { useEffect, useState } from 'react';
import TableComponent from '../../../components/Table/TableComponent';
import useDebounce from '../../../utils/useDebounce';
import { Grid2 } from '@mui/material';
import { CompanyVehiclesResponse } from '../../../interfaces/Vehicles/Response/companyVehicles';
import { ReactComponent as ViewMapIcon } from '../../../assets/icons/location-arrow.svg';
import { ReactComponent as RouteIcon } from '../../../assets/icons/route.svg';
import { ReactComponent as DriverCardIcon } from '../../../assets/icons/address-card.svg';
import { ReactComponent as UnlockIcon } from '../../../assets/icons/unlock.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as UnassignIcon } from '../../../assets/icons/circle-xmark.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/truck.svg';
import { GetUnusedSealsResponse } from '../../../interfaces/Seals/Response/getUnusedSeals';
import { useCookies } from 'react-cookie';
import AddVehicleModal from './Components/AddVehicleModal';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import { CompanyDriversResponse } from '../../../interfaces/Driver/Response/companyDrivers';
import AssignVehicleToDriverModal from './Components/AssignVehicleToDriverModal';
import { VehicleDetails } from '../../../types/VehicleDetails';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { useNavigate } from 'react-router-dom';
import UnassignSealModal from './Components/UnassignSealModal';
import Locking from '../../../components/Locking/Locking';
import AssignVehicleToSealModal from './Components/AssignVehicleToSealModal';
import EditVehicleModal from './Components/EditVehicleModal';
import { setLocation } from '../../../utils/locationUtil';
import { useTranslation } from 'react-i18next';
import { getCompanyDrivers } from '../../../services/Api/apiDriver';
import { assignDriverToVehicle } from '../../../services/Api/apiRoute';
import {
  getUnusedSeals,
  unassignSealFromVehicle,
  assignSealToVehicle,
} from '../../../services/Api/apiSeal';
import {
  getCompanyVehicles,
  addNewVehicle,
  deleteVehicle,
  editVehicle,
} from '../../../services/Api/apiVehicle';
import { CompanyVehiclesRequest } from '../../../interfaces/Vehicles/Request/companyVehicles';
import { CompanyDriversRequest } from '../../../interfaces/Driver/Request/companyDrivers';

function Vehicles() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user', 'role']);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [vehicles, setVehicles] = useState<CompanyVehiclesResponse[]>([]);
  const [drivers, setDrivers] = useState<CompanyDriversResponse[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleDetails | null>(
    null
  );

  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [snackbarRetry, setSnackbarRetry] = useState<(() => void) | undefined>(
    undefined
  );

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [vehicleToDelete, setVehicleToDelete] = useState<VehicleDetails | null>(
    null
  );

  const columnFieldMapping = {
    'Vehicle Alias': 'vehicle_alias',
    'Vehicle Registration': 'vehicle_registration',
    'Driver Name': 'assigned_driver_full_name',
    'iMSeal Serial Number': 'serial_number',
  };

  const columns = Object.keys(columnFieldMapping);

  const columnWidths = ['25%', '20%', '20%', '25%'];

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'Assign to Driver',
      icon: <DriverCardIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedVehicle(row);
        setOpenAssignModal(true);
      },
    },
    {
      label: 'Assign iMSeal to Vehicle',
      icon: <DriverCardIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOpenAssignToVehicle(true);
        setSelectedVehicle(row);
      },
      disabled: row.assigned_seal_id !== null,
    },
    {
      label: 'Unassign iMSeal',
      icon: <UnassignIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedVehicle(row);
        setOpenUnassignSeal(true);
      },
      disabled: row.assigned_seal_id === null,
    },
    {
      label: 'Edit Vehicle Details',
      icon: <EditIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedVehicle(row);
        setEditVehicleFormValues({
          vehicle_registration: row.vehicle_registration as string,
          vehicle_alias: row.vehicle_alias as string,
        });
        setOpenEditVehicleModal(true);
      },
    },
    {
      label: 'Edit/Define Route',
      icon: <RouteIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => navigate(`/vehicles/${row.id}`),
    },
    {
      label: 'View on Map',
      icon: <ViewMapIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedVehicle(row);
        setLocation(
          parseFloat(row.latitude as string),
          parseFloat(row.longitude as string)
        );
        navigate('/');
      },
      disabled: row.assigned_seal_id === null,
    },
    {
      label: 'Delete Vehicle',
      icon: <TrashIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setVehicleToDelete(row as VehicleDetails);
        setOpenDeleteModal(true);
      },
    },
    {
      label:
        row.lock_state === 'LEFT_UNLOCKED'
          ? 'Lock Left'
          : row.lock_state === 'PROCESSING'
          ? 'Processing...'
          : 'Unlock Left',
      icon: (
        <UnlockIcon
          style={{ width: '14px', marginRight: '8px', fill: '#ffffff' }}
        />
      ),
      onClick: () => {
        setOpenLocking(true);
        setLeftLock(true);
        setCurrentLockState(row.lock_state === 'LOCKED');
        setSelectedSeal({
          seal_id: row.assigned_seal_id as string,
          serial_number: row.serial_number as string,
        });
      },
      disabled: !row.assigned_seal_id || row.lock_state === 'PROCESSING',
      isLock: !!row.assigned_seal_id,
    },
    {
      label:
        row.lock_state === 'RIGHT_UNLOCKED'
          ? 'Lock Right'
          : row.lock_state === 'PROCESSING'
          ? 'Processing...'
          : 'Unlock Right',
      icon: (
        <UnlockIcon
          style={{ width: '14px', marginRight: '8px', fill: '#ffffff' }}
        />
      ),
      onClick: () => {
        setOpenLocking(true);
        setLeftLock(false);
        setCurrentLockState(row.lock_state === 'LOCKED');
        setSelectedSeal({
          seal_id: row.assigned_seal_id as string,
          serial_number: row.serial_number as string,
        });
      },
      disabled: !row.assigned_seal_id || row.lock_state === 'PROCESSING',
      isLock: !!row.assigned_seal_id,
    },
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    const fetchVehicles = async () => {
      const payload: CompanyVehiclesRequest = {
        company_id: cookies.user.company_id,
        search_term: debouncedSearchValue,
        page: 1,
        refetch: false,
      };
      try {
        const data = await getCompanyVehicles(payload);
        setVehicles(data.vehicles);
        setHasMore(data.hasMore);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => fetchVehicles);
        setSnackbarOpen(true);
      }
    };

    fetchVehicles();
  }, [cookies.user.company_id, debouncedSearchValue]);

  useEffect(() => {
    const fetchDrivers = async () => {
      const payload: CompanyDriversRequest = {
        company_id: cookies.user.company_id,
        search_term: '',
        page: -1,
        refetch: true,
      };
      try {
        const data = await getCompanyDrivers(payload);
        setDrivers(data.drivers);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarRetry(() => fetchDrivers);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchDrivers();
  }, [cookies.user.company_id]);

  //Below are the fields and methods pertaining to add new vehicle modal

  const [unusedSeals, setUnusedSeals] = useState<GetUnusedSealsResponse[]>([]);
  const [openAddVehicleModal, setOpenAddVehicleModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [addVehicleFormValues, setAddVehicleFormValues] = useState<{
    vehicle_registration: string;
    vehicle_alias: string;
    seal_id: string;
  }>({
    vehicle_registration: '',
    vehicle_alias: '',
    seal_id: ' ',
  });

  const [assignToDriverFormValues, setAssignToDriverFormValues] = useState({
    driver: '',
  });

  const handlePlusIconClick = () => {
    setOpenAddVehicleModal(true);
  };

  useEffect(() => {
    const fetchUnusedSeals = async () => {
      try {
        const data = await getUnusedSeals({
          company_id: cookies.user.company_id,
        });
        setUnusedSeals(data);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => fetchUnusedSeals);
        setSnackbarOpen(true);
      }
    };

    fetchUnusedSeals();
  }, [cookies.user.company_id]);

  const refetchInfo = async () => {
    const fetchUnusedSeals = async () => {
      try {
        const data = await getUnusedSeals({
          company_id: cookies.user.company_id,
        });
        setUnusedSeals(data);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => refetchInfo);
        setSnackbarOpen(true);
      }
    };

    const fetchDrivers = async () => {
      const payload: CompanyDriversRequest = {
        company_id: cookies.user.company_id,
        search_term: '',
        page: -1,
        refetch: true,
      };

      try {
        const data = await getCompanyDrivers(payload);
        setDrivers(data.drivers);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => refetchInfo);
        setSnackbarOpen(true);
      }
    };

    const fetchVehicles = async () => {
      const payload: CompanyVehiclesRequest = {
        company_id: cookies.user.company_id,
        search_term: debouncedSearchValue,
        page: page,
        refetch: true,
      };

      try {
        const data = await getCompanyVehicles(payload);
        setVehicles(data.vehicles);
        setHasMore(data.hasMore);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => refetchInfo);
        setSnackbarOpen(true);
      }
    };

    fetchVehicles();
    fetchDrivers();
    fetchUnusedSeals();
  };

  const handleAddNewVehicle = async () => {
    try {
      const response = await addNewVehicle({
        company_id: cookies.user.company_id,
        vehicle_registration: addVehicleFormValues.vehicle_registration,
        vehicle_alias:
          addVehicleFormValues.vehicle_alias === ''
            ? null
            : addVehicleFormValues.vehicle_alias,
        seal_id:
          addVehicleFormValues.seal_id === ' '
            ? null
            : addVehicleFormValues.seal_id,
        actioned_by:
          cookies.user.first_name +
          ' ' +
          cookies.user.last_name +
          ' - ' +
          cookies.user.role,
      });

      if (response.error === null) {
        setOpenAddVehicleModal(false);
        setAddVehicleFormValues({
          vehicle_registration: '',
          seal_id: ' ',
          vehicle_alias: '',
        });
        refetchInfo();
        setSnackbarMessage('Vehicle added successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Vehicle registration already in use');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleAssignDriverToVehicle = async () => {
    if (!selectedVehicle || !assignToDriverFormValues.driver) {
      setSnackbarMessage('Please select a driver and a vehicle');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const driver = drivers.find(
      (v) => v.full_name === assignToDriverFormValues.driver
    );

    if (!driver) {
      setSnackbarMessage('Selected driver is invalid.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await assignDriverToVehicle({
        vehicle_id: selectedVehicle.id!,
        driver_id: driver.id,
      });

      if (response.statusCode === 200) {
        setSnackbarMessage('Vehicle assigned to Driver successfully');
        setSnackbarSeverity('success');
        refetchInfo();
        setOpenAssignModal(false);
      } else {
        setSnackbarMessage('Failed to assign vehicle');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleDeleteVehicle = async () => {
    if (vehicleToDelete) {
      try {
        const response = await deleteVehicle(vehicleToDelete.id!);
        if (response.success) {
          setVehicles((prevVehicles) =>
            prevVehicles.filter((vehicle) => vehicle.id !== vehicleToDelete.id)
          );
          setSnackbarMessage('Vehicle deleted successfully');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage('Failed to delete vehicle');
          setSnackbarSeverity('error');
        }
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        setOpenDeleteModal(false);
      }
    }
  };

  useEffect(() => {
    if (openAddVehicleModal) {
      setAddVehicleFormValues({
        vehicle_registration: '',
        seal_id: ' ',
        vehicle_alias: '',
      });
    }
  }, [openAddVehicleModal]);

  const [openUnassignSeal, setOpenUnassignSeal] = useState(false);

  const handleUnassignSeal = async () => {
    try {
      const payload = {
        actioned_by:
          cookies.user.first_name +
          ' ' +
          cookies.user.last_name +
          ' - ' +
          cookies.role,
        company_id: cookies.user.company_id,
        seal_id: selectedVehicle?.assigned_seal_id as string,
        vehicle_id: selectedVehicle?.id as string,
      };

      await unassignSealFromVehicle(payload);

      refetchInfo();
      setOpenUnassignSeal(false);
      setSnackbarMessage('Seal Unassigned from Vehicle');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const [openLocking, setOpenLocking] = useState(false);
  const [leftLock, setLeftLock] = useState(false);
  const [currentLockState, setCurrentLockState] = useState(false);
  const [selectedSeal, setSelectedSeal] = useState({
    seal_id: '',
    serial_number: '',
  });

  const [openAssignToVehicle, setOpenAssignToVehicle] = useState(false);

  const handleAssignToVehicle = async () => {
    try {
      const payload = {
        actioned_by:
          cookies.user.first_name +
          ' ' +
          cookies.user.last_name +
          ' - ' +
          cookies.role,
        company_id: cookies.user.company_id,
        seal_id: selectedSeal.seal_id,
        vehicle_id: selectedVehicle?.id as string,
      };

      await assignSealToVehicle(payload);

      refetchInfo();
      setSelectedVehicle(null);
      setOpenAssignToVehicle(false);
      setSnackbarMessage('Seal Assigned to Vehicle');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const [openEditVehicleModal, setOpenEditVehicleModal] = useState(false);
  const [editVehicleFormValues, setEditVehicleFormValues] = useState<{
    vehicle_registration: string;
    vehicle_alias: string;
  }>({
    vehicle_registration: selectedVehicle?.vehicle_registration || '',
    vehicle_alias: selectedVehicle?.vehicle_alias || '',
  });

  const handleEditVehicle = async () => {
    try {
      const response = await editVehicle({
        id: selectedVehicle?.id as string,
        company_id: cookies.user.company_id,
        vehicle_registration: editVehicleFormValues.vehicle_registration,
        vehicle_alias:
          editVehicleFormValues.vehicle_alias === ''
            ? null
            : editVehicleFormValues.vehicle_alias,
      });

      if (response.error === null) {
        setOpenEditVehicleModal(false);
        setEditVehicleFormValues({
          vehicle_registration: '',
          vehicle_alias: '',
        });
        refetchInfo();
        setSnackbarMessage('Vehicle edited successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Vehicle registration already in use');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handlePageChange = async () => {
    const payload: CompanyVehiclesRequest = {
      company_id: cookies.user.company_id,
      search_term: debouncedSearchValue,
      page: page,
      refetch: false,
    };
    try {
      const data = await getCompanyVehicles(payload);
      setVehicles((prevVehicles) => {
        const newVehicles = data.vehicles;
        const updatedVehicles = prevVehicles.filter(
          (prevVehicle) =>
            !newVehicles.some(
              (newVehicle: CompanyVehiclesResponse) =>
                newVehicle.id === prevVehicle.id
            )
        );
        return [...updatedVehicles, ...newVehicles];
      });
      setHasMore(data.hasMore);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const setCurrentPage = (page: number) => {
    setPage(page);
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <TableComponent
          title={t('Vehicle List')}
          onPlusIconClick={handlePlusIconClick}
          onSearchValueChange={handleSearchChange}
          searchValue={searchValue}
          columns={columns}
          data={vehicles}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          columnWidths={columnWidths}
          onPageChange={handlePageChange}
          setCurrentPage={setCurrentPage}
          hasMore={hasMore}
        />
      </Grid2>

      <AddVehicleModal
        open={openAddVehicleModal}
        onClose={() => setOpenAddVehicleModal(false)}
        formValues={addVehicleFormValues}
        setFormValues={setAddVehicleFormValues}
        unusedSeals={unusedSeals}
        onSubmit={handleAddNewVehicle}
      />

      <EditVehicleModal
        open={openEditVehicleModal}
        onClose={() => setOpenEditVehicleModal(false)}
        formValues={editVehicleFormValues}
        setFormValues={setEditVehicleFormValues}
        onSubmit={handleEditVehicle}
      />

      <AssignVehicleToDriverModal
        open={openAssignModal}
        onClose={() => setOpenAssignModal(false)}
        vehicleDetails={selectedVehicle || {}}
        drivers={drivers}
        formValues={assignToDriverFormValues}
        setFormValues={setAssignToDriverFormValues}
        onSubmit={handleAssignDriverToVehicle}
      />

      <UnassignSealModal
        open={openUnassignSeal}
        onClose={() => setOpenUnassignSeal(false)}
        onSubmit={handleUnassignSeal}
      />

      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={handleDeleteVehicle}
        type="Vehicle"
        name={vehicleToDelete?.vehicle_registration || ''}
      />

      <Locking
        open={openLocking}
        onClose={() => {
          setOpenLocking(false);
          refetchInfo();
        }}
        unlockLeft={leftLock}
        currentState={currentLockState}
        serial_number={selectedSeal.serial_number}
        company_id={cookies.user.company_id}
      />

      <AssignVehicleToSealModal
        open={openAssignToVehicle}
        onClose={() => setOpenAssignToVehicle(false)}
        vehicleDetails={selectedVehicle || {}}
        seals={unusedSeals}
        formValues={selectedSeal}
        setFormValues={setSelectedSeal}
        onSubmit={handleAssignToVehicle}
      />

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        onRetry={snackbarRetry}
      />
    </Grid2>
  );
}

export default Vehicles;
