import {
  Button,
  Grid2,
  styled,
  Switch,
  SwitchProps,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import modalTheme from '../../../theme/modalTheme';
import { useCookies } from 'react-cookie';
import ConfirmSaveModal from './Components/ConfirmSaveModal';
import { EditSettingsRequest } from '../../../interfaces/Company/Request/editSettings';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import { updateCompanySettings } from '../../../services/Api/apiCompany';

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  'width': 52,
  'height': 26,
  'padding': 0,
  '& .MuiSwitch-switchBase': {
    'padding': 0,
    'margin': 2,
    'transitionDuration': '300ms',
    '&.Mui-checked': {
      'transform': 'translateX(26px)',
      'color': '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#B4EE2B',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#B4EE2B',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#B4EE2B',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));

function Settings() {
  const [cookies] = useCookies(['user', 'role', 'settings']);
  const [openSave, setOpenSave] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('error');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const [settingValues, setSettingValues] = useState({
    id: cookies.settings.id,
    driver_unlock: cookies.settings.driver_unlock,
    driver_skip: cookies.settings.driver_skip,
    geozone_radius: cookies.settings.geozone_radius,
  });

  const handleCancel = () => {
    setSettingValues({
      id: cookies.settings.id,
      driver_unlock: cookies.settings.driver_unlock,
      driver_skip: cookies.settings.driver_skip,
      geozone_radius: cookies.settings.geozone_radius,
    });
  };

  const handleSaveSettings = async () => {
    const changeSettings = async () => {
      try {
        const payload: EditSettingsRequest = {
          id: settingValues.id,
          company_id: cookies.user.company_id,
          driver_unlock: settingValues.driver_unlock,
          driver_skip: settingValues.driver_skip,
          geozone_radius: settingValues.geozone_radius,
        };
        await updateCompanySettings(payload);

        setOpenSave(false);
        setSnackbarMessage('Settings successfully changed.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch {
        setSnackbarMessage('Error changing settings.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };
    changeSettings();
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <Grid2
          container
          display={'flex'}
          direction={'column'}
          spacing={2}
          sx={{
            height: '100%',
            minHeight: '250px',
            maxWidth: '100%',
          }}
        >
          <Grid2 container display={'flex'} alignItems={'baseline'}>
            <Typography variant="h4">Settings</Typography>
          </Grid2>
          <Grid2
            container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
            }}
          >
            <Grid2
              container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '20px',
                height: '600px',
                width: '60%',
                backgroundColor: '#F5F5F5',
                borderRadius: '16px',
                padding: '20px 80px',
              }}
            >
              <Grid2
                container
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Grid2 // Copy from here to Closing tag to add new entry
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '15px 0',
                    borderBottom: '2px solid #E5E5E5',
                  }}
                >
                  <Grid2
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '18px',
                      }}
                    >
                      Automatic Skip Requests
                    </Typography>
                    <Typography
                      sx={{
                        color: '#838383',
                        fontSize: '14px',
                      }}
                    >
                      Allow skip route requests to process without Admin
                      approval?
                    </Typography>
                  </Grid2>
                  <Grid2>
                    <CustomSwitch
                      checked={settingValues.driver_skip}
                      disabled={cookies.role !== 'ADMIN'}
                      onChange={() => {
                        setSettingValues({
                          ...settingValues,
                          driver_skip: !settingValues.driver_skip,
                        });
                      }}
                    ></CustomSwitch>
                  </Grid2>
                </Grid2>

                <Grid2 // Copy from here to Closing tag to add new entry
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '15px 0',
                    borderBottom: '2px solid #E5E5E5',
                  }}
                >
                  <Grid2
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '18px',
                      }}
                    >
                      Driver Unlock Permissions
                    </Typography>
                    <Typography
                      sx={{
                        color: '#838383',
                        fontSize: '14px',
                      }}
                    >
                      Allow assigned Drivers to unlock iMSeals in GeoZones
                      without Admin approval?
                    </Typography>
                  </Grid2>
                  <Grid2>
                    <CustomSwitch
                      checked={settingValues.driver_unlock}
                      disabled={cookies.role !== 'ADMIN'}
                      onChange={() => {
                        setSettingValues({
                          ...settingValues,
                          driver_unlock: !settingValues.driver_unlock,
                        });
                      }}
                    ></CustomSwitch>
                  </Grid2>
                </Grid2>

                <Grid2 // Copy from here to Closing tag to add new entry
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '15px 0',
                    borderBottom: '2px solid #E5E5E5',
                  }}
                >
                  <Grid2
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '18px',
                      }}
                    >
                      GeoZone Radius
                    </Typography>
                    <Typography
                      sx={{
                        color: '#C7C7C7',
                        fontSize: '14px',
                      }}
                    >
                      Define GeoZone radius size in meters:
                    </Typography>
                  </Grid2>
                  <Grid2>
                    <ThemeProvider theme={modalTheme}>
                      <TextField
                        value={settingValues.geozone_radius}
                        onChange={(e) => {
                          setSettingValues({
                            ...settingValues,
                            geozone_radius: e.target.value,
                          });
                        }}
                        disabled={cookies.role !== 'ADMIN' || true}
                        sx={{
                          'width': '100px',

                          '& .MuiInputBase-input': {
                            textAlign: 'right',
                          },
                        }}
                      ></TextField>
                    </ThemeProvider>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 flexGrow={1}></Grid2>
              <Grid2
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                  gap: '15px',
                }}
              >
                <Button
                  onClick={handleCancel}
                  disabled={cookies.role !== 'ADMIN'}
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '12px',
                    border: '1px solid #E5E5E5',
                    color: '#000',
                    textTransform: 'none',
                    padding: '8px 20px',
                  }}
                >
                  <Typography>Cancel</Typography>
                </Button>
                <Button
                  onClick={() => setOpenSave(true)}
                  disabled={cookies.role !== 'ADMIN'}
                  sx={{
                    'backgroundColor': '#000',
                    'borderRadius': '12px',
                    'color': '#fff',
                    'textTransform': 'none',
                    'padding': '8px 20px',

                    '&:disabled': {
                      color: '#E5E5E5',
                    },
                  }}
                >
                  <Typography>Save Changes</Typography>
                </Button>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
      <ConfirmSaveModal
        open={openSave}
        onClose={() => setOpenSave(false)}
        onSubmit={handleSaveSettings}
      />

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
    </Grid2>
  );
}

export default Settings;
