import { AxiosError } from 'axios';
import { GetHistoryRequest } from '../../interfaces/History/Request/getHistory';
import axiosInstance from '../../utils/axiosInstance';

export const getHistory = async (data: GetHistoryRequest) => {
  try {
    const response = await axiosInstance.post('/history/get-history', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};
