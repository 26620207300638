import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import shieldIcon from '../../../assets/icons/shield-halved.svg';
import eyeOpen from '../../../assets/icons/eye_on.svg';
import eyeClose from '../../../assets/icons/eye_off.svg';
import ShowPasswordStrength from './ShowPasswordStrength';
import { useLocation, useNavigate } from 'react-router-dom';
import { zxcvbn } from '@zxcvbn-ts/core';
import { useTranslation } from 'react-i18next';
import {
  resetPassword,
  validatePasswordToken,
} from '../../../services/Api/userService';

type Strength = 0 | 1 | 2 | 3 | 4;

interface CreateNewPasswordComponentProps {
  firstPassword: boolean;
}

const CreateNewPasswordComponent: React.FC<CreateNewPasswordComponentProps> = ({
  firstPassword,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [strength, setStrength] = useState<Strength>(0);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [email] = useState(location.state?.email || '');
  const [token] = useState(location.state?.token || '');
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const { t } = useTranslation();
  const [validation, setValidation] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const [validationError, setValidationError] = useState<string | null>(null);
  const validationToken = queryParams.get('token');
  const [firstUseEmail, setFirstUseEmail] = useState('');
  //   const [firstUseOTP, setFirstUseOTP] = useState('');

  const calculateStrength = (password: string): Strength => {
    const result = zxcvbn(password);

    if (result.score === 0 && password.length >= 8) {
      return 1;
    }
    return result.score as Strength;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    setApiError(null);
    setStrength(calculateStrength(value));

    if (confirmPassword && value !== confirmPassword) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setApiError(null);

    if (value !== password) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (firstPassword) {
      handleValidation();
    }
  }, [firstPassword]);

  const handleValidation = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await validatePasswordToken(validationToken || '');
      if (response.success) {
        setValidation(true);
        setFirstUseEmail(response.data.email);
        // setFirstUseOTP(response.data.otp);
      } else {
        setValidationError(
          t(
            'Request to change password is invalid or expired. Please contact support for further assistance.'
          )
        );
        setValidation(false);
      }
    } catch {
      setValidation(false);
      setValidationError(
        t(
          'Request to change password is invalid or expired. Please contact support for further assistance.'
        )
      );
    }
  };

  const handleCreateNewPassword = async () => {
    setLoading(true);
    setApiError(null);

    try {
      const response = await resetPassword(
        firstPassword ? firstUseEmail : email,
        password,
        firstPassword ? validationToken || '' : token,
        firstPassword ? validationToken || '' : ''
      );
      if (response.success) {
        navigate('/login', { replace: true });
      } else {
        setApiError(response.message);
      }
    } catch {
      setApiError(
        'An error occurred while resetting the password. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && isFormValid && !loading && validation) {
      event.preventDefault();
      handleCreateNewPassword();
    }
  };

  const isFormValid =
    password.length >= 8 &&
    password === confirmPassword &&
    password.trim() !== '' &&
    confirmPassword.trim() !== '';

  return (
    <Box
      sx={{
        display: 'flex',
        width: '21.5vw',
        height: firstPassword && !validation ? 'auto' : '63.03vh',
        borderRadius: '16.224px',
        border: '2px solid',
        borderColor: 'var(--Primary-Lime, #B4EE2B)',
        backgroundColor: 'var(--white-100, #FFF)',
        padding: '4.69vh 3.70vw 6.56vh 3.70vw',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'relative',
        gap: '1.06vw',
        flexShrink: 0,
      }}
      onKeyDown={handleKeyDown}
    >
      {firstPassword && !validation ? (
        <>
          {validationError ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  paddingTop: '3.75vh',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '1.88vh',
                  alignSelf: 'stretch',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '5.22vw',
                    height: '9.24vh',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4.62vh',
                  }}
                >
                  <img
                    src={shieldIcon}
                    style={{ width: '100%', height: '100%' }}
                    alt="Shield Icon"
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    paddingTop: '2.25vh',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '1.85vh',
                    alignSelf: 'stretch',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Open Sans',
                      fontSize: '1.04vw',
                      fontWeight: 600,
                      lineHeight: '2.22vh',
                      textAlign: 'center',
                      marginTop: '3.70vh',
                    }}
                  >
                    {validationError}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  paddingTop: '3.75vh',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '1.88vh',
                  alignSelf: 'stretch',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '5.22vw',
                    height: '9.24vh',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4.62vh',
                  }}
                >
                  <CircularProgress sx={{ color: '#B4EE2B' }} size="5.21vw" />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    paddingTop: '2.25vh',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '1.85vh',
                    alignSelf: 'stretch',
                  }}
                >
                  <Typography
                    sx={{
                      alignSelf: 'stretch',
                      fontFamily: 'Open Sans',
                      fontSize: '1.04vw',
                      fontWeight: 600,
                      lineHeight: '2.22vh',
                      textAlign: 'center',
                      marginTop: '3.70vh',
                    }}
                  >
                    {t('Awaiting validation...')}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              paddingTop: '3.75vh',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1.88vh',
              alignSelf: 'stretch',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '5.22vw',
                height: '9.24vh',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4.62vh',
              }}
            >
              <img
                src={shieldIcon}
                style={{ width: '100%', height: '100%' }}
                alt="Shield Icon"
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                paddingTop: '2.25vh',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '0.56vh',
                alignSelf: 'stretch',
              }}
            >
              <Typography
                sx={{
                  alignSelf: 'stretch',
                  fontFamily: 'Open Sans',
                  fontSize: '1.67vw',
                  fontWeight: 600,
                  lineHeight: '3.70vh',
                  textAlign: 'center',
                  color: '#000000',
                }}
              >
                {t('Create new Password')}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                height: '1.88vh',
                flexDirection: 'column',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
              }}
            ></Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '1.88vh',
              alignSelf: 'stretch',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '1.39vh',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '0.32vw',
                }}
              >
                <TextField
                  placeholder={t('Enter New Password')}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && isFormValid && !loading) {
                      handleCreateNewPassword();
                    }
                  }}
                  fullWidth
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            <img
                              src={showPassword ? eyeOpen : eyeClose}
                              alt={
                                showPassword
                                  ? t('Hide Password')
                                  : t('Show Password')
                              }
                              style={{
                                width: '1.25vw',
                                height: '2.22vh',
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: '#FFFFFF',
                        borderRadius: '0.42vw',
                        color: '#000',
                        fontFamily: 'Open Sans',
                        fontSize: '0.84vw',
                        fontWeight: 400,
                        lineHeight: '1.27vw',
                        textAlign: 'left',
                      },
                    },
                  }}
                  sx={{
                    'backgroundColor': '#FFFFFF',
                    'borderRadius': '0.42vw',
                    'marginBottom': '1.30vh',
                    '& .MuiOutlinedInput-root': {
                      'borderColor': '#C7C7C7',
                      '& fieldset': {
                        borderColor: '#C7C7C7',
                      },
                      '&:hover fieldset': {
                        borderColor: '#C7C7C7',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#C7C7C7',
                      },
                    },
                    '& .MuiInputBase-input': {
                      padding: '0.73vw',
                      color: '#000',
                      fontFamily: 'Open Sans',
                      fontSize: '0.84vw',
                      fontWeight: 400,
                      lineHeight: '1.27vw',
                      textAlign: 'left',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#C7C7C7',
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                      color: '#C7C7C7',
                    },
                    '& .MuiInputBase-input::placeholder': {
                      color: '#C7C7C7',
                      fontFamily: 'Open Sans',
                      fontSize: '0.84vw',
                      fontWeight: 400,
                      lineHeight: '1.27vw',
                      textAlign: 'left',
                    },
                  }}
                />
              </Box>
              <ShowPasswordStrength strength={strength} />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '0.3125vw',
                alignSelf: 'stretch',
              }}
            >
              <TextField
                placeholder={t('Re-enter New Password')}
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && isFormValid && !loading) {
                    handleCreateNewPassword();
                  }
                }}
                fullWidth
                error={errorMessage}
                helperText={errorMessage ? t('Passwords do not match') : ''}
                slotProps={{
                  formHelperText: {
                    sx: { textAlign: 'left', marginLeft: '0px' },
                  },
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          <img
                            src={showConfirmPassword ? eyeOpen : eyeClose}
                            alt={
                              showConfirmPassword
                                ? t('Hide Password')
                                : t('Show Password')
                            }
                            style={{
                              width: '1.25vw',
                              height: '2.22vh',
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      backgroundColor: '#FFFFFF',
                      borderRadius: '0.42vw',
                      color: '#000',
                      fontFamily: 'Open Sans',
                      fontSize: '0.84vw',
                      fontWeight: 400,
                      lineHeight: '1.27vw',
                      textAlign: 'left',
                    },
                  },
                }}
                sx={{
                  'backgroundColor': '#FFFFFF',
                  'borderRadius': '0.42vw',
                  '& .MuiOutlinedInput-root': {
                    'borderColor': '#C7C7C7',
                    '& fieldset': {
                      borderColor: '#C7C7C7',
                    },
                    '&:hover fieldset': {
                      borderColor: '#C7C7C7',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#C7C7C7',
                    },
                  },
                  '& .MuiInputBase-input': {
                    padding: '0.73vw',
                    color: '#000',
                    fontFamily: 'Open Sans',
                    fontSize: '0.84vw',
                    fontWeight: 400,
                    lineHeight: '1.27vw',
                    textAlign: 'left',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#C7C7C7',
                  },
                  '& .MuiFormLabel-root.Mui-focused': {
                    color: '#C7C7C7',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: '#C7C7C7',
                    fontFamily: 'Open Sans',
                    fontSize: '0.84vw',
                    fontWeight: 400,
                    lineHeight: '1.27vw',
                    textAlign: 'left',
                  },
                }}
              />
            </Box>
          </Box>

          {apiError && (
            <Typography
              sx={{
                alignSelf: 'stretch',
                textAlign: 'center',
                color: 'red',
                fontSize: '0.73vw',
                marginBottom: '0.92vh',
              }}
            >
              {apiError}
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              paddingTop: '3.00vh',
              flexDirection: 'column',
              alignItems: 'flex-end',
              flexShrink: 0,
              alignSelf: 'stretch',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                paddingBottom: '2.25vh',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '1.12vh',
                alignSelf: 'stretch',
              }}
            >
              <Button
                sx={{
                  backgroundColor: isFormValid ? '#000000' : '#EEEEEE',
                  color: isFormValid ? '#FFFFFF' : '#838383',
                  padding: '1.48vh 1.25vw',
                  borderRadius: '0.83vw',
                  width: '100%',
                  fontSize: '0.94vw',
                  lineHeight: '2.59vh',
                  fontFamily: "'Open Sans', sans-serif",
                  textAlign: 'center',
                  textTransform: 'none',
                  display: 'inline-block',
                  position: 'relative',
                  cursor: isFormValid ? 'pointer' : 'not-allowed',
                }}
                disabled={!isFormValid || loading}
                onClick={handleCreateNewPassword}
              >
                {t('Create Password')}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreateNewPasswordComponent;
