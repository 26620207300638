import React, { useEffect } from 'react';
import { Grid2 } from '@mui/material';
import SignUpForm from './Components/SignUpComponent';
import background from '../../assets/images/login_background.svg';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user', 'super_user', 'token']);

  useEffect(() => {
    const token = cookies.token;
    const superUser = cookies.super_user;

    if (token) {
      if (superUser) {
        navigate('/admin/clients');
      } else {
        navigate('/');
      }
    }
  }, [navigate]);

  return (
    <Grid2
      container
      display={'flex'}
      alignContent={'center'}
      alignItems={'center'}
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
      }}
    >
      <Grid2
        sx={{
          position: 'absolute',
          top: '10.07%',
          left: '52.66%',
        }}
      >
        <SignUpForm />
      </Grid2>
    </Grid2>
  );
};

export default LoginForm;
