import React from 'react';
import {
  Snackbar,
  Alert,
  SnackbarCloseReason,
  AlertTitle,
  Button,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh.svg';

interface SnackbarComponentProps {
  message: string;
  severity: 'success' | 'error' | 'warning' | 'info';
  duration?: number;
  open: boolean;
  onClose: (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => void;
  onRetry?: () => void;
}

const getTitle = (severity: string) => {
  switch (severity) {
    case 'error':
      return 'Request Failed';
    case 'warning':
      return 'Warning';
    case 'info':
      return 'Information';
    case 'success':
      return 'Successful Request';
    default:
      return 'Notification';
  }
};

const SnackbarComponent: React.FC<SnackbarComponentProps> = ({
  message,
  severity,
  duration = 2000000,
  open,
  onClose,
  onRetry,
}) => {
  const { t } = useTranslation();
  const title = getTitle(severity);

  const handleRetry = () => {
    if (onRetry) {
      onRetry();
    }
    onClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={onClose}>
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        action={
          severity === 'error' && onRetry ? (
            <Button
              color="inherit"
              size="small"
              onClick={handleRetry}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                height: '28px',
                borderRadius: '8px',
                padding: '8px',
                minWidth: 'auto',
              }}
            >
              <RefreshIcon
                style={{ width: '16px', height: '16px', fill: '#FFFFFF' }}
              />
            </Button>
          ) : null
        }
        sx={{
          'width': '300px',
          'fontFamily': 'Open Sans',
          'borderRadius': '16px',
          'boxShadow': '0px 4px 12px rgba(0, 0, 0, 0.1)',
          '& .MuiAlertTitle-root': {
            fontSize: '16px',
            fontFamily: 'Open Sans',
            fontWeight: 400,
            marginBottom: '6px',
          },
          '& .MuiAlert-message': {
            margin: '0 auto',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'Open Sans',
            fontWeight: 275,
            marginBottom: '0px',
          },
        }}
      >
        <AlertTitle>{t(title)}</AlertTitle>
        {message ? (
          <>
            <div style={{ paddingBottom: '8px' }}>{t(message)}</div>
            {severity === 'error' && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: '12px',
                    width: '100%',
                    margin: '0 auto',
                    textAlign: 'center',
                  }}
                >
                  {t(
                    'If the problem persists, please contact support at support@47660588.hubspot-inbox.com.'
                  )}
                </Typography>
              </>
            )}
          </>
        ) : (
          ''
        )}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
