import React from 'react';
import { Grid2 } from '@mui/material';
import background from '../../assets/images/login_background.svg';
import CreateNewPasswordComponent from './Components/CreateNewPasswordComponent';

const CreateNewPassword = () => {
  return (
    <Grid2
      container
      display={'flex'}
      alignContent={'center'}
      alignItems={'center'}
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
      }}
    >
      <Grid2
        sx={{
          position: 'absolute',
          top: '10.07%',
          left: '52.66%',
        }}
      >
        <CreateNewPasswordComponent firstPassword={false} />
      </Grid2>
    </Grid2>
  );
};

export default CreateNewPassword;
