import React, { useEffect, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import modalTheme from '../../../../theme/modalTheme';
import GenericAutocomplete from '../../../../components/Map/MapComponents/GenericAutocomplete';
import { APIProvider } from '@vis.gl/react-google-maps';
import env from 'react-dotenv';
import { useTranslation } from 'react-i18next';

const MAP_KEY = env.REACT_APP_MAPS_API_KEY;

interface LocationModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isEditMode?: boolean;
  PaperProps?: object;
  formValues: {
    id: string;
    name: string;
    address: string;
    lat: string;
    lng: string;
  };
  setFormValues: React.Dispatch<
    React.SetStateAction<{
      id: string;
      name: string;
      address: string;
      lat: string;
      lng: string;
    }>
  >;
}

const LocationModal: React.FC<LocationModalProps> = ({
  open,
  onClose,
  isEditMode = false,
  formValues,
  setFormValues,
  onSubmit,
  PaperProps,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!open) {
      setFormValues({
        id: '',
        name: '',
        address: '',
        lat: '',
        lng: '',
      });
    }
  }, [open, setFormValues]);

  const { t } = useTranslation();

  const handlePlaceSelect = (place: google.maps.places.PlaceResult | null) => {
    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setFormValues({
        ...formValues,
        address: place.formatted_address || '',
        lat: lat.toString(),
        lng: lng.toString(),
      });
    } else {
      setFormValues({
        ...formValues,
        lat: '',
        lng: '',
      });
    }
  };
  const isFormValid =
    formValues.name.trim() !== '' &&
    (formValues.lat?.toString().trim() ?? '') !== '' &&
    (formValues.lng?.toString().trim() ?? '') !== '';

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFormValid && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFormValid, onSubmit, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose} PaperProps={PaperProps}>
        <DialogTitle>
          {isEditMode ? t('Edit Location Name') : t('Add New Location')}
        </DialogTitle>
        <Typography variant="subtitle1">
          {isEditMode
            ? t('Edit the name of the location below, address cannot be edited')
            : t(
                'Enter the details below in order to create a new location, please select the appropriate address from the autocomplete options'
              )}
        </Typography>
        <DialogContent>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Location name')}*
          </Typography>
          <TextField
            placeholder={t('Enter Location Name')}
            type="text"
            value={formValues.name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
            fullWidth
            sx={{ mb: 2, backgroundColor: 'white' }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Address')}*
          </Typography>
          {isEditMode ? (
            <TextField
              value={formValues.address}
              disabled
              fullWidth
              sx={{ mb: 2, backgroundColor: 'white' }}
            />
          ) : (
            <>
              <APIProvider apiKey={MAP_KEY}>
                <GenericAutocomplete
                  onPlaceSelect={handlePlaceSelect}
                  inputRef={inputRef}
                />
              </APIProvider>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!isFormValid}
            onClick={onSubmit}
            color="primary"
          >
            {isEditMode ? t('Edit Location') : t('Create Location')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default LocationModal;
