import React, { useEffect, useState } from 'react';
import TableComponent from '../../../components/Table/TableComponent';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import useDebounce from '../../../utils/useDebounce';
import { Grid2 } from '@mui/material';
import { ReactComponent as DriverCardIcon } from '../../../assets/icons/address-card.svg';
import { ReactComponent as ViewMapIcon } from '../../../assets/icons/location-arrow.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { useCookies } from 'react-cookie';

import { CompanyOperatorsResponse } from '../../../interfaces/Operators/Response/CompanyOperators';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import AddOperatorModal from './Components/AddOperatorModal';
import { CompanyLocationsResponse } from '../../../interfaces/Location/Response/companyLocations';
import { setLocation } from '../../../utils/locationUtil';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  addLocation,
  getCompanyLocations,
} from '../../../services/Api/apiLocation';
import {
  getCompanyOperators,
  updateCompanyOperator,
  createCompanyOperator,
  deleteCompanyOperator,
} from '../../../services/Api/apiOperator';
import { GetCompanyOperatorsRequest } from '../../../interfaces/Operators/Request/GetCompanyOperators';
import {
  addCompanyLocationRequest,
  CompanyLocationsRequest,
} from '../../../interfaces/Location/Request/companyLocations';
import LocationModal from '../Locations/Components/LocationModal';
import { generateRandomString } from '../../../utils/generatePassword';

interface EditOperatorDetails {
  id: string;
  first_name: string;
  surname: string;
  contact_number: string;
  email: string;
  location_id: string;
}

function Operators() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user', 'role']);
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [selectedRow, setSelectedRow] =
    useState<CompanyOperatorsResponse | null>();

  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const [addOperatorFormValues, setAddOperatorFormValues] = useState({
    first_name: '',
    surname: '',
    contact_number: '',
    email: '',
    location_id: '',
  });

  const [locationModalValues, setLocationModalValues] = useState({
    id: '',
    name: '',
    address: '',
    lat: '',
    lng: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [snackbarRetry, setSnackbarRetry] = useState<(() => void) | undefined>(
    undefined
  );

  const [operators, setOperators] = useState<CompanyOperatorsResponse[]>([]);
  const [locations, setLocations] = useState<CompanyLocationsResponse[]>([]);

  const [openAddOperatorModal, setOpenAddOperatorModal] = useState(false);
  const [openAddLocationModal, setOpenAddLocationModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [operatorToDelete, setOperatorToDelete] =
    useState<CompanyOperatorsResponse | null>(null);

  const columnFieldMapping = {
    'Name': 'full_name',
    'Contact Number': 'contact_number',
    'Email': 'email',
    'Fixed Location': 'location',
  };
  const columns = Object.keys(columnFieldMapping);
  const columnWidths = ['20%', '15%', '20%', '20%'];

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'Edit Operator Details',
      icon: <DriverCardIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        const transformedRow: EditOperatorDetails = {
          id: row.id as string,
          first_name: row.first_name as string,
          surname: row.last_name as string,
          contact_number: row.contact_number as string,
          email: row.email as string,
          location_id: row.location_id as string,
        };
        setSelectedRow(row as unknown as CompanyOperatorsResponse);
        handleEditOperatorClick(transformedRow);
      },
    },
    {
      label: 'View on Map',
      icon: <ViewMapIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedRow(row as unknown as CompanyOperatorsResponse);
        setLocation(
          parseFloat(row.latitude as string),
          parseFloat(row.longitude as string)
        );
        navigate('/');
      },
    },
    {
      label: 'Delete Operator',
      icon: <TrashIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOperatorToDelete(row as unknown as CompanyOperatorsResponse);
        setOpenDeleteModal(true);
      },
    },
  ];

  useEffect(() => {
    const fetchOperators = async () => {
      const payload: GetCompanyOperatorsRequest = {
        company_id: cookies.user.company_id,
        search_term: debouncedSearchValue,
        page: 1,
        refetch: false,
      };

      try {
        const data = await getCompanyOperators(payload);
        setOperators(data.operators);
        setHasMore(data.hasMore);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => fetchOperators);
        setSnackbarOpen(true);
      }
    };

    fetchOperators();
  }, [cookies.user.company_id, debouncedSearchValue]);

  const fetchLocations = async () => {
    try {
      const payload: CompanyLocationsRequest = {
        company_id: cookies.user.company_id,
        search_term: '',
        page: -1,
        refetch: true,
      };
      const data = await getCompanyLocations(payload);
      setLocations(data.locations);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarRetry(() => fetchLocations);
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [cookies.user.company_id]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  const handleAddNewOperator = async () => {
    try {
      const operatorData = {
        first_name: addOperatorFormValues.first_name,
        last_name: addOperatorFormValues.surname,
        contact_number: addOperatorFormValues.contact_number,
        email: addOperatorFormValues.email,
        company_id: cookies.user.company_id,
        location_id: addOperatorFormValues.location_id,
        password: generateRandomString(20),
      };

      let response;
      if (isEditMode) {
        response = await updateCompanyOperator({
          ...operatorData,
          id: selectedRow?.id || '',
        });
      } else {
        response = await createCompanyOperator(operatorData);
      }

      if (response.error === null) {
        setSnackbarMessage(
          isEditMode
            ? 'Operator updated successfully.'
            : 'Operator added successfully.'
        );
        setSnackbarSeverity('success');

        const payload: GetCompanyOperatorsRequest = {
          company_id: cookies.user.company_id,
          search_term: '',
          page: 1,
          refetch: true,
        };

        const updatedOperators = await getCompanyOperators(payload);
        setOperators(updatedOperators.operators);
        setHasMore(updatedOperators.hasMore);
      } else {
        setSnackbarMessage(response.message || 'An error occurred.');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      closeAddOperatorModal();
      setAddOperatorFormValues({
        first_name: '',
        surname: '',
        contact_number: '',
        email: '',
        location_id: '',
      });
    }
  };

  const handleDeleteOperator = async () => {
    if (operatorToDelete) {
      try {
        const response = await deleteCompanyOperator(operatorToDelete.id!);
        if (response.success) {
          setOperators((prevOperator) =>
            prevOperator.filter(
              (operator) => operator.id !== operatorToDelete.id
            )
          );
          setSnackbarMessage('Operator deleted successfully');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage('Failed to delete operator');
          setSnackbarSeverity('error');
        }
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        setOpenDeleteModal(false);
      }
    }
  };

  const closeAddOperatorModal = () => {
    setOpenAddOperatorModal(false);
    setIsEditMode(false);
  };

  const handlePlusIconClick = () => {
    setOpenAddOperatorModal(true);
  };

  const handleEditOperatorClick = (operator: EditOperatorDetails) => {
    setAddOperatorFormValues({
      first_name: operator.first_name || '',
      surname: operator.surname || '',
      contact_number: operator.contact_number || '',
      email: operator.email || '',
      location_id: operator.location_id || '',
    });
    setIsEditMode(true);
    setOpenAddOperatorModal(true);
  };

  const handlePageChange = async () => {
    const payload: GetCompanyOperatorsRequest = {
      company_id: cookies.user.company_id,
      search_term: debouncedSearchValue,
      page: page,
      refetch: false,
    };
    try {
      const data = await getCompanyOperators(payload);
      setOperators((prevOperators) => {
        const newOperators = data.operators;
        const updatedOperators = prevOperators.filter(
          (prevOperator) =>
            !newOperators.some(
              (newOperator: CompanyOperatorsResponse) =>
                newOperator.id === prevOperator.id
            )
        );
        return [...updatedOperators, ...newOperators];
      });
      setHasMore(data.hasMore);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const setCurrentPage = (page: number) => {
    setPage(page);
  };

  const handleAddLocationOpen = () => {
    setOpenAddLocationModal(true);
  };

  const handleAddLocationClose = () => {
    setOpenAddLocationModal(false);
  };

  const handleAddLocationSubmit = async () => {
    const payload: addCompanyLocationRequest = {
      company_id: cookies.user.company_id,
      name: locationModalValues.name,
      address: locationModalValues.address,
      latitude: parseFloat(locationModalValues.lat),
      longitude: parseFloat(locationModalValues.lng),
    };

    try {
      const response = await addLocation(payload);
      if (response.success) {
        setLocations((prevLocations) => [...prevLocations, response.data]);
        setSnackbarSeverity('success');
        setSnackbarMessage('Location added successfully');
        fetchLocations();
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage('Failed to add location');
      }
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setOpenAddLocationModal(false);
    }
    setOpenAddLocationModal(false);
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <TableComponent
          title={t('Operators')}
          onPlusIconClick={handlePlusIconClick}
          onSearchValueChange={handleSearchChange}
          searchValue={searchValue}
          columns={columns}
          data={operators}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          columnWidths={columnWidths}
          onPageChange={handlePageChange}
          setCurrentPage={setCurrentPage}
          hasMore={hasMore}
        />
      </Grid2>

      <AddOperatorModal
        open={openAddOperatorModal}
        onClose={closeAddOperatorModal}
        onSubmit={handleAddNewOperator}
        formValues={addOperatorFormValues}
        setFormValues={setAddOperatorFormValues}
        locations={locations}
        isEditMode={isEditMode}
        handleAddLocationOpen={handleAddLocationOpen}
        aria-hidden={openAddLocationModal}
      />

      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={handleDeleteOperator}
        type="Operator"
        name={operatorToDelete?.full_name || ''}
      />

      <LocationModal
        open={openAddLocationModal}
        onClose={handleAddLocationClose}
        onSubmit={handleAddLocationSubmit}
        formValues={locationModalValues}
        setFormValues={setLocationModalValues}
        PaperProps={{
          style: {
            position: 'absolute',
            top: '20%',
            left: '64%',
            marginLeft: 16,
          },
        }}
      />

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        onRetry={snackbarRetry}
      />
    </Grid2>
  );
}

export default Operators;
