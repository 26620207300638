import React, { useEffect, useState } from 'react';
import TableComponent from '../../../components/Table/TableComponent';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import useDebounce from '../../../utils/useDebounce';
import { Grid2 } from '@mui/material';

import { ReactComponent as EditIcon } from '../../../assets/icons/user-pen.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/gear.svg';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';
import { ReactComponent as AccessClientIcon } from '../../../assets/icons/access-client.svg';
import { useCookies } from 'react-cookie';
import { GetAllClientsResponse } from '../../../interfaces/Company/Response/getAllClients';
import { GetAllClientsRequest } from '../../../interfaces/Company/Request/getAllClients';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import ChangeStatusModal from './Components/ChangeStatusModal';
import { setSearch } from '../../../utils/searchUtil';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddClientModal from './Components/AddClientModal';
import EditClientModal from './Components/EditClientModal';
import { getAllClients } from '../../../services/Api/apiAdmin';
import {
  deleteCompany,
  addClientsAdmin,
  editClientsAdmin,
} from '../../../services/Api/apiCompany';
import { superUserIntoClient } from '../../../services/Api/userService';
import { generateRandomString } from '../../../utils/generatePassword';

function AdminClient() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['user']);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [clients, setClients] = useState<GetAllClientsResponse[]>([]);
  const { t } = useTranslation();

  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const [openAddClient, setOpenAddClient] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    contact_first_name: '',
    contact_last_name: '',
    contact_number: '',
    email: '',
    status: '',
  });
  const columnFieldMapping = {
    'Client': 'client',
    'Active Devices': 'active_devices',
    'Account Status': 'account_status',
    'Contact Person': 'contact_person',
    'Contact Number': 'contact_number',
    'Email': 'email',
  };

  const columns = Object.keys(columnFieldMapping);

  const columnWidths = ['20%', '13%', '13%', '18%', '18%', '18%'];

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'Edit Client Details',
      icon: <EditIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setClientToEdit(row as unknown as GetAllClientsResponse);
        setFormValues({
          name: row.client as string,
          contact_first_name: row.contact_first_name as string,
          contact_last_name: row.contact_last_name as string,
          contact_number: row.contact_number as string,
          email: row.email as string,
          status: row.account_status as string,
        });
        setOpenEditClient(true);
      },
    },
    {
      label: 'View All iMSeals',
      icon: <LockIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSearch(row.client as string);
        navigate('/admin/seals');
      },
    },
    {
      label: 'Access Client Portal',
      icon: <AccessClientIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        handleClientLogin(row.id as string);
      },
    },
    {
      label: 'Delete Account',
      icon: <DeleteIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setClientToDelete(row as unknown as GetAllClientsResponse);
        setOpenDeleteModal(true);
      },
    },
    {
      label: 'Change Account Status',
      icon: <SettingsIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setClientToEdit(row as unknown as GetAllClientsResponse);
        setOpenChangeStatusModal(true);
      },
    },
  ];

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [clientToDelete, setClientToDelete] =
    useState<GetAllClientsResponse | null>(null);

  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);

  useEffect(() => {
    setSearch('');
    const fetchClients = async () => {
      const payload: GetAllClientsRequest = {
        admin_email: cookies.user.email,
        search_term: debouncedSearchValue,
        page: 1,
        refetch: false,
      };

      try {
        const data = await getAllClients(payload);

        const modifiedData = data.clients.map(
          (client: GetAllClientsResponse) => ({
            ...client,
            contact_person: `${client.contact_first_name} ${client.contact_last_name}`,
          })
        );

        setClients(modifiedData);
        setHasMore(data.hasMore);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };
    fetchClients();
  }, [debouncedSearchValue, cookies.user.email]);

  const refetchInfo = async () => {
    const payload: GetAllClientsRequest = {
      admin_email: cookies.user.email,
      search_term: debouncedSearchValue,
      page: page,
      refetch: true,
    };

    try {
      const data = await getAllClients(payload);

      const modifiedData = data.clients.map(
        (client: GetAllClientsResponse) => ({
          ...client,
          contact_person: `${client.contact_first_name} ${client.contact_last_name}`,
        })
      );

      setClients(modifiedData);
      setHasMore(data.hasMore);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleDeleteClient = async () => {
    if (clientToDelete) {
      try {
        const response = await deleteCompany(clientToDelete.id!);
        if (response.success) {
          refetchInfo();
          setSnackbarMessage('Client deleted successfully');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage(response.message || 'Failed to delete client');
          setSnackbarSeverity('error');
        }
      } catch {
        setSnackbarMessage('Could not delete client');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        setOpenDeleteModal(false);
      }
    }
  };

  const handlePlusIconClick = () => {
    setOpenAddClient(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  const handleAddClient = async () => {
    try {
      const response = await addClientsAdmin({
        ...formValues,
        password: generateRandomString(20),
      });
      if (response.statusCode === 201) {
        setSnackbarMessage('Client Added successfully');
        setSnackbarSeverity('success');
        refetchInfo();
      } else {
        setSnackbarMessage(response.message || 'Error adding client');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      if (error instanceof Error) {
        setSnackbarMessage(error.message || 'Error adding client');
      } else {
        setSnackbarMessage('Error adding client');
      }
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      handleClose();
    }
  };

  const resetForm = () => {
    setFormValues({
      name: '',
      contact_first_name: '',
      contact_last_name: '',
      contact_number: '',
      email: '',
      status: '',
    });
  };

  const handleClose = () => {
    setOpenAddClient(false);
    setOpenEditClient(false);
    resetForm();
  };

  // Methods pertaining to edit client modal

  const [openEditClient, setOpenEditClient] = useState(false);
  const [clientToEdit, setClientToEdit] =
    useState<GetAllClientsResponse | null>(null);

  const handleEditClient = async () => {
    try {
      const response = await editClientsAdmin({
        name: formValues.name,
        contact_first_name: formValues.contact_first_name,
        contact_last_name: formValues.contact_last_name,
        contact_number: formValues.contact_number,
        id: clientToEdit?.id || '',
        email: formValues.email,
      });
      if (response.statusCode === 200) {
        setSnackbarMessage('Client Edited successfully');
        setSnackbarSeverity('success');
        refetchInfo();
      } else {
        setSnackbarMessage(response.message || 'Error editing client');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      if (error instanceof Error) {
        setSnackbarMessage(error.message || 'Error editing client');
      } else {
        setSnackbarMessage('Error editing client');
      }
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      handleClose();
    }
  };

  const handleChangeStatus = async (newStatus: string) => {
    try {
      if (clientToEdit) {
        const updatedClientData = {
          name: clientToEdit.client,
          contact_first_name: clientToEdit.contact_first_name,
          contact_last_name: clientToEdit.contact_last_name,
          contact_number: clientToEdit.contact_number,
          id: clientToEdit.id,
          email: clientToEdit.email,
          status: newStatus.toLocaleUpperCase(),
        };

        const response = await editClientsAdmin(updatedClientData);
        if (response.statusCode === 200) {
          setSnackbarMessage('Client status edited successfully');
          setSnackbarSeverity('success');
          refetchInfo();
        } else {
          setSnackbarMessage(response.message || 'Error editing client status');
          setSnackbarSeverity('error');
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setSnackbarMessage(error.message || 'Error editing client status');
      } else {
        setSnackbarMessage('Error editing client status');
      }
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setOpenChangeStatusModal(false);
    }
  };

  const handleClientLogin = async (clientId: string) => {
    try {
      const response = await superUserIntoClient({
        email: cookies.user.email,
        company_id: clientId,
      });

      if (response.statusCode === 200) {
        setCookie(
          'user',
          { ...cookies.user, company_id: clientId },
          { path: '/' }
        );
        navigate('/');
      } else {
        setSnackbarMessage('Could not access client portal');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch {
      setSnackbarMessage('Could not access client portal');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handlePageChange = async () => {
    const payload: GetAllClientsRequest = {
      admin_email: cookies.user.email,
      search_term: debouncedSearchValue,
      page: page,
      refetch: false,
    };
    try {
      const data = await getAllClients(payload);

      setClients((prevClients) => {
        const newClients = data.clients.map(
          (client: GetAllClientsResponse) => ({
            ...client,
            contact_person: `${client.contact_first_name} ${client.contact_last_name}`,
          })
        );

        const updatedClients = prevClients.filter(
          (prevClient) =>
            !newClients.some(
              (newClient: GetAllClientsResponse) =>
                newClient.id === prevClient.id
            )
        );

        return [...updatedClients, ...newClients];
      });

      setHasMore(data.hasMore);
    } catch {
      setSnackbarMessage('Error loading more data');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const setCurrentPage = (page: number) => {
    setPage(page);
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <TableComponent
          title={t('Clients')}
          onPlusIconClick={handlePlusIconClick}
          onSearchValueChange={handleSearchChange}
          searchValue={searchValue}
          columns={columns}
          data={clients}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          columnWidths={columnWidths}
          onPageChange={handlePageChange}
          setCurrentPage={setCurrentPage}
          hasMore={hasMore}
        />
      </Grid2>

      <AddClientModal
        open={openAddClient}
        onClose={handleClose}
        onSubmit={handleAddClient}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      <EditClientModal
        open={openEditClient}
        onClose={handleClose}
        onSubmit={handleEditClient}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={handleDeleteClient}
        type="Client"
        name={clientToDelete?.client || ''}
      />

      <ChangeStatusModal
        open={openChangeStatusModal}
        onClose={() => setOpenChangeStatusModal(false)}
        onConfirm={handleChangeStatus}
        clientData={clientToEdit}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </Grid2>
  );
}

export default AdminClient;
