import { AxiosError } from 'axios';
import { AddClientRequest } from '../../interfaces/Company/Request/addClient';
import { EditClientRequest } from '../../interfaces/Company/Request/editClient';
import { EditSettingsRequest } from '../../interfaces/Company/Request/editSettings';
import axiosInstance from '../../utils/axiosInstance';

export const addClientsAdmin = async (data: AddClientRequest) => {
  try {
    const response = await axiosInstance.post('/company/create-company', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};

export const editClientsAdmin = async (data: EditClientRequest) => {
  try {
    const response = await axiosInstance.put('/company/update-company', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};

export const deleteCompany = async (id: string) => {
  try {
    await axiosInstance.delete(`/company/delete-company`, {
      params: { id },
    });
    return { success: true };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return {
        success: false,
        message: error.response.data.message || 'Failed to delete Company',
      };
    } else {
      throw error;
    }
  }
};

export const updateCompanySettings = async (data: EditSettingsRequest) => {
  try {
    const response = await axiosInstance.put(
      '/company/update-company-settings',
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};
