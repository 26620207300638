import axiosInstance from '../../utils/axiosInstance';
import { AxiosError } from 'axios';

export const getClientMapMarkers = async (data: { company_id: string }) => {
  try {
    const response = await axiosInstance.post(
      '/company/get-company-map-markers',
      { company_id: data.company_id }
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};
