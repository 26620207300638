import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  strength: 0 | 1 | 2 | 3 | 4;
}

const ShowPasswordStrength = ({ strength }: Props) => {
  const strengthColors = ['#DD3333', '#FF9500', '#FFCC00', '#B4EE2B'];

  const indicatorColors = ['#E5E5E5', '#E5E5E5', '#E5E5E5', '#E5E5E5'];

  if (strength >= 1) indicatorColors[0] = strengthColors[0];
  if (strength >= 2) indicatorColors[1] = strengthColors[1];
  if (strength >= 3) indicatorColors[2] = strengthColors[2];
  if (strength === 4) indicatorColors[3] = strengthColors[3];

  const message =
    strength === 0
      ? 'Use a mininum 8 characters with a mix of letters, numbers and symbols.'
      : strength === 1
      ? 'Weak password. Try adding more characters or symbols.'
      : strength === 2
      ? 'Okay password! Consider improving it further.'
      : strength === 3
      ? 'Good password! You are almost there.'
      : 'Strong password!';

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '0.52vw',
        }}
      >
        <Box
          sx={{
            paddingLeft: '1.5vw',
            paddingRight: '2.08vw',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '17.5vw',
              alignItems: 'center',
              gap: '1.04vw',
              alignSelf: 'stretch',
            }}
          >
            {Array.from({ length: 4 }).map((_, index) => (
              <Box
                key={index}
                sx={{
                  height: '0.37vh',
                  flex: '1 0 0',
                  borderRadius: '0.52vw',
                  backgroundColor: indicatorColors[index],
                }}
              ></Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Typography
        sx={{
          paddingLeft: '1.5vw',
          paddingRight: '2.08vw',
          fontFamily: 'Open Sans',
          fontWeight: 400,
          fontSize: '0.625vw',
          fontStyle: 'normal',
          lineHeight: '1.66vh',
          color: '#838383',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          fontFeatureSettings: "'ss01' on, 'cv01' on, 'cv11' on",
        }}
      >
        {message}
      </Typography>
    </>
  );
};

export default ShowPasswordStrength;
