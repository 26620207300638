import React, { useState, useRef, useCallback } from 'react';
import { Marker, InfoWindow } from '@vis.gl/react-google-maps';
import { Typography } from '@mui/material';
import './CircleMarker.css';

interface CircleMarkerProps {
  position: google.maps.LatLngLiteral;
  mag: number;
  name: string;
  description?: string;
  setMarkerRef: (marker: google.maps.Marker | null, key: string) => void;
}

const createCustomIcon = (mag: number) => {
  const scale = Math.pow(2, mag) / 2;
  const outerRadius = scale * 10;
  const innerRadius = scale * 5;

  const svg = `
        <svg width="${outerRadius * 2}" height="${
    outerRadius * 2
  }" viewBox="0 0 ${outerRadius * 2} ${
    outerRadius * 2
  }" xmlns="http://www.w3.org/2000/svg">
          <circle cx="${outerRadius}" cy="${outerRadius}" r="${outerRadius}" fill="black" fill-opacity="0.4" />
          <circle cx="${outerRadius}" cy="${outerRadius}" r="${innerRadius}" fill="black" stroke="white" stroke-width="3" />
        </svg>
      `;

  return {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
    scaledSize: new google.maps.Size(outerRadius * 2, outerRadius * 2),
    anchor: new google.maps.Point(outerRadius, outerRadius),
  };
};

const CircleMarker: React.FC<CircleMarkerProps> = ({
  position,
  mag,
  name,
  description,
  setMarkerRef,
}) => {
  const [isInfoWindowVisible, setInfoWindowVisible] = useState(false);
  const markerRef = useRef<google.maps.Marker | null>(null);

  const handleClick = () => setInfoWindowVisible(true);
  const ref = useCallback(
    (marker: google.maps.Marker) => {
      setMarkerRef(marker, name);
      markerRef.current = marker;
    },
    [setMarkerRef, name]
  );

  return (
    <>
      <Marker
        position={position}
        icon={createCustomIcon(mag)}
        onClick={handleClick}
        ref={ref}
      />
      {isInfoWindowVisible && (
        <InfoWindow
          anchor={markerRef.current}
          onCloseClick={() => setInfoWindowVisible(false)}
        >
          <div
            style={{
              width: '200px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              gap: '0',
              padding: '0 24px 24px 24px',
            }}
          >
            <Typography
              align="center"
              style={{
                fontFamily: 'Open Sans',
                color: '#232323',
                marginBottom: '10px',
                fontSize: '1.2rem',
              }}
            >
              {name}
            </Typography>
            <Typography
              align="center"
              style={{
                fontFamily: 'Open Sans',
                color: '#232323',
                fontSize: '0.8rem',
              }}
            >
              {description}
            </Typography>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default CircleMarker;
