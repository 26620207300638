import { Box, Button, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as NoDataIcon } from '../../assets/icons/face-monocle.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NoDataState: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '20px',
        height: 'calc(100vh - 350px)',
      }}
    >
      <Typography
        variant="h6"
        align="center"
        fontSize={'30px'}
        fontWeight={600}
      >
        {t('No Data')}
      </Typography>
      <NoDataIcon
        style={{
          fill: '#86BE02',
          width: '70px',
          height: '70px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography>{t('Click the + button to add data')}</Typography>
        <Typography>
          {t('Or if this is unexpected please contact support.')}
        </Typography>
        <Button
          sx={{
            marginTop: '20px',
            textTransform: 'none',
            backgroundColor: '#E5E5E5',
            borderRadius: '8px',
            color: '#000',
            padding: '8px 20px',
          }}
          onClick={() => navigate('/support')}
        >
          {t('Contact Support')}
        </Button>
      </Box>
    </Box>
  );
};

export default NoDataState;
