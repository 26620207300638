import { AxiosError } from 'axios';
import { GetAllClientsRequest } from '../../interfaces/Company/Request/getAllClients';
import { AssignSealAdminRequest } from '../../interfaces/Seals/Request/assignSealsAdmin';
import { GetAllSealsAdminRequest } from '../../interfaces/Seals/Request/getAllSealsAdmin';
import axiosInstance from '../../utils/axiosInstance';

export const getAllClients = async (data: GetAllClientsRequest) => {
  try {
    const response = await axiosInstance.post(
      '/admin/get-all-company-info',
      data
    );
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const getAllSealsAdmin = async (data: GetAllSealsAdminRequest) => {
  try {
    const response = await axiosInstance.post('/admin/get-all-seals', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const assignMassSeals = async (data: AssignSealAdminRequest) => {
  try {
    const response = await axiosInstance.post('/admin/assign-seals', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};
