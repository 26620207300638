import { AxiosError } from 'axios';
import { AddNewDriver } from '../../interfaces/Driver/Request/addNewDriver';
import { CompanyDriverCardsRequest } from '../../interfaces/Driver/Request/companyDriverCards';
import { CompanyDriversRequest } from '../../interfaces/Driver/Request/companyDrivers';
import { EditDriverDetails } from '../../interfaces/Driver/Request/updateDriverDetails';
import axiosInstance from '../../utils/axiosInstance';

export const getCompanyDriverCards = async (
  data: CompanyDriverCardsRequest
) => {
  try {
    const response = await axiosInstance.post('/driver/company-drivers', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const getCompanyDrivers = async (data: CompanyDriversRequest) => {
  try {
    const response = await axiosInstance.post('/driver/get-driver', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const addNewDriver = async (data: AddNewDriver) => {
  try {
    const response = await axiosInstance.post('/driver/create-driver', data);

    if (response.data.statusCode === 201) {
      return {
        success: true,
        message: 'Request successful',
        data: response.data.data,
      };
    } else {
      return {
        success: false,
        message: response.data.message || 'Unexpected error',
        error: response.data.error || null,
      };
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const updateDriver = async (data: EditDriverDetails) => {
  try {
    const response = await axiosInstance.put('/driver/update-driver', data);

    if (response.data.statusCode === 200) {
      return {
        success: true,
        message: 'Driver updated successfully',
        data: response.data.data,
      };
    } else {
      return {
        success: false,
        message: response.data.message || 'Unexpected error',
        error: response.data.error || null,
      };
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const deleteDriver = async (id: string) => {
  try {
    await axiosInstance.delete(`/driver/delete-driver`, {
      params: { id },
    });
    return { success: true };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};
