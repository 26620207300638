import { AxiosError } from 'axios';
import {
  CompanyLocationsRequest,
  addCompanyLocationRequest,
  updateCompanyLocationRequest,
} from '../../interfaces/Location/Request/companyLocations';
import axiosInstance from '../../utils/axiosInstance';

export const getCompanyLocations = async (data: CompanyLocationsRequest) => {
  try {
    const response = await axiosInstance.post('/location/get-location', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const addLocation = async (data: addCompanyLocationRequest) => {
  try {
    const response = await axiosInstance.post(
      '/location/create-location',
      data
    );
    return { success: true, data: response.data };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const editLocation = async (data: updateCompanyLocationRequest) => {
  try {
    const response = await axiosInstance.put('/location/update-location', data);
    return { success: true, data: response.data };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const deleteLocation = async (
  company_id: string,
  location_id: string
) => {
  try {
    await axiosInstance.delete(`/location/delete-location`, {
      params: { company_id, location_id },
    });
    return { success: true };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};
