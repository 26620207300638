import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  InputAdornment,
  Autocomplete,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/magnifying-glass.svg';
import modalTheme from '../../../../theme/modalTheme';
import { CompanyVehiclesResponse } from '../../../../interfaces/Vehicles/Response/companyVehicles';
import { useTranslation } from 'react-i18next';

interface AssignSealToVehicleModalProps {
  open: boolean;
  onClose: () => void;
  sealDetails: {
    id?: string;
    serial_number?: string;
    vehicle_id?: string;
    driver_id?: string;
    driver_full_name?: string;
    status?: string;
    lock_state?: string;
  };
  formValues: { vehicle: string };
  setFormValues: React.Dispatch<React.SetStateAction<{ vehicle: string }>>;
  vehicles: CompanyVehiclesResponse[];
  onSubmit: () => void;
}

const AssignSealToVehicleModal: React.FC<AssignSealToVehicleModalProps> = ({
  open,
  onClose,
  sealDetails,
  formValues,
  setFormValues,
  vehicles,
  onSubmit,
}) => {
  useEffect(() => {
    if (!open) {
      setFormValues({ vehicle: '' });
    }
  }, [open, setFormValues]);
  const { t } = useTranslation();

  const isFormValid = formValues.vehicle.trim() !== '';

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFormValid && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSubmit, isFormValid, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{ '& .MuiDialog-paper': { width: '480px' } }}
      >
        <DialogTitle>
          {t('Assign')} {sealDetails?.serial_number || 'N/A'}
        </DialogTitle>
        <Typography variant="subtitle1">{t('Search for a vehicle')}</Typography>
        <DialogContent>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Vehicle')}
          </Typography>
          <Autocomplete
            options={vehicles}
            getOptionLabel={(option) => option.vehicle_alias}
            value={
              vehicles.find((v) => v.vehicle_alias === formValues.vehicle) ||
              null
            }
            onChange={(event, newValue) => {
              setFormValues({ vehicle: newValue?.id || '' });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Vehicle Alias"
                fullWidth
                sx={{ mb: 2, backgroundColor: 'white' }}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{
                            height: '14px',
                            width: '15px',
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!isFormValid}
            onClick={onSubmit}
            color="primary"
          >
            {t('Assign to Vehicle')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AssignSealToVehicleModal;
