import { AxiosError } from 'axios';
import { AddCommandRequest } from '../../interfaces/Seals/Request/addCommand';
import { AssignSealToCompanyRequest } from '../../interfaces/Seals/Request/assignSealToCompany';
import { AssignSealToVehicleRequest } from '../../interfaces/Seals/Request/assignSealToVehicle';
import { GetAllSealsRequest } from '../../interfaces/Seals/Request/getAllSeals';
import { GetCompanySealsRequest } from '../../interfaces/Seals/Request/getCompanySeals';
import { GetUnusedSealsRequest } from '../../interfaces/Seals/Request/getUnusedSeals';
import { UnassignSealFromVehicleRequest } from '../../interfaces/Seals/Request/unassignSealFromVehicle';
import axiosInstance from '../../utils/axiosInstance';
import { DeactivateSealRequest } from '../../interfaces/Seals/Request/deactivateSeal';

type ApiError = {
  message: string;
  response?: {
    data: {
      message: string;
    };
  };
};

export const getUnusedSeals = async (data: GetUnusedSealsRequest) => {
  try {
    const response = await axiosInstance.post('/seal/get-unused-seals', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const getCompanySeals = async (data: GetCompanySealsRequest) => {
  try {
    const response = await axiosInstance.post('/seal/get-seal', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const assignSealToCompany = async (data: AssignSealToCompanyRequest) => {
  try {
    const response = await axiosInstance.put('/seal/claim-seal', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const getSealConfirmDummyCSV = async () => {
  try {
    const response = await axiosInstance.get(
      '/seal/dummy-seal-template-client'
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const uploadConfirmSealCsv = async (data: FormData) => {
  try {
    const response = await axiosInstance.post('/seal/claim-seals-csv', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const getDummyCsv = async () => {
  try {
    const response = await axiosInstance.get('/seal/dummy-seal-template');
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const uploadSealCsv = async (data: FormData) => {
  try {
    const response = await axiosInstance.post('/seal/upload-seals-csv', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const getAllSeals = async (data: GetAllSealsRequest) => {
  try {
    const response = await axiosInstance.post('/seal/get-seal', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const assignSealToVehicle = async (data: AssignSealToVehicleRequest) => {
  try {
    const response = await axiosInstance.put(
      '/seal/assign-seal-to-vehicle',
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const unassignSealFromVehicle = async (
  data: UnassignSealFromVehicleRequest
) => {
  try {
    const response = await axiosInstance.put(
      '/seal/remove-seal-from-vehicle',
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

// export const addCommand = async (data: AddCommandRequest) => {
//     try {
//       const response = await axiosInstance.post('/seal/add-command', data);
//       return response.data;
//     } catch (error: unknown) {
//       if (error instanceof AxiosError && error.response) {
//         throw new Error(error.response.data.message);
//       } else {
//         throw error;
//       }
//     }
//   };

export const addCommand = async (data: AddCommandRequest) => {
  try {
    const response = await axiosInstance.post('/seal/add-command', data);
    return response.data;
  } catch (error: unknown) {
    if (isApiError(error)) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        'An unknown error occurred';
      throw new Error(errorMessage);
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
};

function isApiError(error: unknown): error is ApiError {
  return (error as ApiError).message !== undefined;
}

export const deactivateSeal = async (data: DeactivateSealRequest) => {
  try {
    const response = await axiosInstance.post('/seal/deactivate-seal', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const reactivateSeal = async (data: DeactivateSealRequest) => {
  try {
    const response = await axiosInstance.post('/seal/reactivate-seal', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};

export const removeSeal = async (data: DeactivateSealRequest) => {
  try {
    const response = await axiosInstance.post('/seal/remove-seal', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
};
