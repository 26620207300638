import { Grid2 } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import useDebounce from '../../../utils/useDebounce';

import AddDevicesCSV from './Components/AddDevicesCSV';
import { GetAllSealsAdminRequest } from '../../../interfaces/Seals/Request/getAllSealsAdmin';
import { GetAllSealsAdminResponse } from '../../../interfaces/Seals/Response/getAllSealsAdmin';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import { getSearch } from '../../../utils/searchUtil';
import { getAllSealsAdmin } from '../../../services/Api/apiAdmin';
import TableComponent from '../../../components/Table/TableComponent';

import { ReactComponent as LinkIcon } from '../../../assets/icons/link.svg';
import { ReactComponent as BanSolid } from '../../../assets/icons/ban-solid.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';

import AssignDevice from './Components/AssignDevice';
import DeactivateSealAdminModal from './Components/DeactivateSeal';
import {
  deactivateSeal,
  reactivateSeal,
  removeSeal,
} from '../../../services/Api/apiSeal';
import ReactivateSealAdminModal from './Components/ReactivateSeal';
import DeleteSealAdmin from './Components/DeleteSeal';

const AdminSeal: React.FC = () => {
  const [cookies] = useCookies(['user']);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [seals, setSeals] = useState<GetAllSealsAdminResponse[]>([]);
  const [openCSVUpload, setOpenCSVUpload] = useState(false);

  const [openAssignDevice, setOpenAssignDevice] = useState(false);

  const [openDeactivateSeal, setOpenDeactivateSeal] = useState(false);
  const [openReactivateSeal, setOpenReactivateSeal] = useState(false);

  const [openDeleteSeal, setOpenDeleteSeal] = useState(false);

  const [selectedSeal, setSelectedSeal] = useState('');

  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const columnFieldMapping = {
    'Serial Number': 'serial_number',
    'Client': 'company_name',
    'Status': 'status',
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('error');
  const [snackbarRetry, setSnackbarRetry] = useState<(() => void) | undefined>(
    undefined
  );

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    setSearchValue(getSearch().search);
  }, []);

  const columns = Object.keys(columnFieldMapping);

  const columnWidths = ['30%', '25%', '25%'];

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'Assign device to Client',
      icon: <LinkIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedSeal(row.id as string);
        setOpenAssignDevice(true);
      },
      disabled: row.status === 'IN_USE',
    },
    {
      label:
        row.status === 'DEACTIVATED'
          ? 'Reactivate iMSeal'
          : 'Deactivate iMSeal',
      icon: <BanSolid style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedSeal(row.id as string);
        if (row.status === 'DEACTIVATED') {
          setOpenReactivateSeal(true);
        } else {
          setOpenDeactivateSeal(true);
        }
      },
      disabled: false,
    },
    {
      label: 'Delete iMSeal',
      icon: <TrashIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedSeal(row.id as string);
        setOpenDeleteSeal(true);
      },
      disabled: row.status === 'DEACTIVATED' ? false : true,
    },
  ];

  const fetchSeals = async () => {
    try {
      const payload: GetAllSealsAdminRequest = {
        admin_email: cookies.user.email,
        search_term: debouncedSearchValue,
        page: 1,
        refetch: false,
      };
      const data = await getAllSealsAdmin(payload);
      setSeals(data.seals);
      setHasMore(data.hasMore);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarRetry(() => fetchSeals);
      setSnackbarOpen(true);
    }
  };

  const refetchInfo = async () => {
    const payload: GetAllSealsAdminRequest = {
      admin_email: cookies.user.email,
      search_term: debouncedSearchValue,
      page: page,
      refetch: true,
    };
    const data = await getAllSealsAdmin(payload);
    setSeals(data.seals);
    setHasMore(data.hasMore);
  };

  useEffect(() => {
    fetchSeals();
    // eslint-disable-next-line
  }, [debouncedSearchValue, cookies.user.email]);

  const handlePlusIconClick = () => {
    setOpenCSVUpload(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handlePageChange = async () => {
    const payload: GetAllSealsAdminRequest = {
      admin_email: cookies.user.email,
      search_term: debouncedSearchValue,
      page: page,
      refetch: false,
    };
    try {
      const data = await getAllSealsAdmin(payload);
      setSeals((prevSeals) => {
        const newSeals = data.seals;
        const updatedSeals = prevSeals.filter(
          (prevSeal) =>
            !newSeals.some(
              (newSeal: GetAllSealsAdminResponse) => newSeal.id === prevSeal.id
            )
        );
        return [...updatedSeals, ...newSeals];
      });
      setHasMore(data.hasMore);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const onSuccess = () => {
    refetchInfo();
  };

  const handleDeactivateSeal = async () => {
    try {
      const payload = {
        user_id: cookies.user.id,
        seal_id: selectedSeal,
      };

      await deactivateSeal(payload);

      refetchInfo();
      setOpenDeactivateSeal(false);
      setSnackbarMessage('Seal Deactivated');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteSeal = async () => {
    try {
      const payload = {
        user_id: cookies.user.id,
        seal_id: selectedSeal,
      };

      await removeSeal(payload);

      refetchInfo();
      setOpenDeleteSeal(false);
      setSnackbarMessage('Seal Deleted');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleReactivateSeal = async () => {
    try {
      const payload = {
        user_id: cookies.user.id,
        seal_id: selectedSeal,
      };

      await reactivateSeal(payload);

      refetchInfo();
      setOpenReactivateSeal(false);
      setSnackbarMessage('Seal Reactivated');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <TableComponent
          title="iMSeal Locks"
          onPlusIconClick={handlePlusIconClick}
          onSearchValueChange={handleSearchChange}
          searchValue={searchValue}
          columns={columns}
          data={seals}
          hasMore={hasMore}
          actions={actions}
          setCurrentPage={setPage}
          onPageChange={handlePageChange}
          columnFieldMapping={columnFieldMapping}
          columnWidths={columnWidths}
        />
      </Grid2>
      <AddDevicesCSV
        open={openCSVUpload}
        onClose={() => setOpenCSVUpload(false)}
        onUploadSuccess={fetchSeals}
      />
      <AssignDevice
        open={openAssignDevice}
        onClose={() => setOpenAssignDevice(false)}
        selectedSeal={selectedSeal}
        onSuccess={onSuccess}
      />
      <DeactivateSealAdminModal
        open={openDeactivateSeal}
        onClose={() => setOpenDeactivateSeal(false)}
        onSubmit={
          selectedSeal !== ''
            ? handleDeactivateSeal
            : () => setOpenDeactivateSeal(false)
        }
      />
      <ReactivateSealAdminModal
        open={openReactivateSeal}
        onClose={() => setOpenReactivateSeal(false)}
        onSubmit={
          selectedSeal !== ''
            ? handleReactivateSeal
            : () => setOpenReactivateSeal(false)
        }
      />
      <DeleteSealAdmin
        open={openDeleteSeal}
        onClose={() => setOpenDeleteSeal(false)}
        onSubmit={
          selectedSeal !== ''
            ? handleDeleteSeal
            : () => setOpenDeleteSeal(false)
        }
      />
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        onRetry={snackbarRetry}
      />
    </Grid2>
  );
};

export default AdminSeal;
