import React, { useEffect, useState } from 'react';

import useDebounce from '../../../utils/useDebounce';
import { Grid2 } from '@mui/material';

import { ReactComponent as ViewMapIcon } from '../../../assets/icons/location-arrow.svg';
import { useCookies } from 'react-cookie';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import HistoryTableComponent from '../../../components/Table/HistoryTable';
import { GetHistoryResponse } from '../../../interfaces/History/Response/getHistory';
import { GetHistoryRequest } from '../../../interfaces/History/Request/getHistory';
import { setLocation } from '../../../utils/locationUtil';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { getHistory } from '../../../services/Api/apiHistory';

function History() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user']);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const { t } = useTranslation();
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const [history, setHistory] = useState<GetHistoryResponse[]>([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [snackbarRetry, setSnackbarRetry] = useState<(() => void) | undefined>(
    undefined
  );

  const columnFieldMapping = {
    'iMSeal ID': 'seal',
    'Status': 'status',
    'Date': 'created_at',
    'Vehicle': 'vehicle_alias',
    'Action': 'action',
    'Actioned By': 'actioned_by',
  };

  const columns = Object.keys(columnFieldMapping);

  const columnWidths = ['15%', '10%', '15%', '15%', '20%', '15%'];

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'View on Map',
      icon: <ViewMapIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setLocation(
          parseFloat(row.latitude as string),
          parseFloat(row.longitude as string)
        );
        navigate('/');
      },
    },
  ];

  const handleTimeChange = (dataInput: GetHistoryResponse[]) => {
    dataInput.forEach((entry) => {
      if (entry.created_at !== '') {
        try {
          entry.created_at = format(
            new Date(entry.created_at),
            'HH:mm - dd MMM yyyy'
          );
        } catch {
          entry.created_at = 'Invalid Date';
        }
      }
    });

    return dataInput;
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      const payload: GetHistoryRequest = {
        company_id: cookies.user.company_id,
        search_term: debouncedSearchValue,
        page: 1,
        refetch: false,
      };
      try {
        const data = await getHistory(payload);
        setHistory(handleTimeChange(data.history));
        setHasMore(data.hasMore);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarRetry(() => fetchHistory);
        setSnackbarOpen(true);
      }
    };

    fetchHistory();
  }, [debouncedSearchValue, cookies.user.company_id]);

  const refetchHistory = (from: Date | undefined, to: Date | undefined) => {
    const fetchHistory = async () => {
      const payload: GetHistoryRequest = {
        company_id: cookies.user.company_id,
        search_term: debouncedSearchValue,
        dateFrom: from,
        dateTo: to,
        page: -1,
        refetch: true,
      };
      try {
        const data = await getHistory(payload);
        setHistory(handleTimeChange(data.history));
        setHasMore(data.hasMore);
      } catch (error) {
        setSnackbarMessage((error as Error).message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchHistory();
  };

  const handlePageChange = async () => {
    const payload: GetHistoryRequest = {
      company_id: cookies.user.company_id,
      search_term: debouncedSearchValue,
      page: page,
      refetch: false,
    };
    try {
      const data = await getHistory(payload);

      const formattedData = handleTimeChange(data.history);
      setHistory((prevHistories) => {
        const newHistories = formattedData;
        const updatedHistories = prevHistories.filter(
          (prevHistory) =>
            !newHistories.some(
              (newHistory: GetHistoryResponse) =>
                newHistory.id === prevHistory.id
            )
        );
        return [...updatedHistories, ...newHistories];
      });
      setHasMore(data.hasMore);
    } catch (error) {
      setSnackbarMessage((error as Error).message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const setCurrentPage = (page: number) => {
    setPage(page);
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <HistoryTableComponent
          title={t('History Log')}
          onSearchValueChange={handleSearchChange}
          onApplyDates={refetchHistory}
          searchValue={searchValue}
          columns={columns}
          data={history}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          columnWidths={columnWidths}
          onPageChange={handlePageChange}
          setCurrentPage={setCurrentPage}
          hasMore={hasMore}
        />
      </Grid2>

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        onRetry={snackbarRetry}
      />
    </Grid2>
  );
}

export default History;
