import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import gritLogo from '../../../assets/icons/grit-logo.svg';
import MegaFortress from '../../../assets/images/MegaFortress.png';
import {
  validateEmail,
  validatePasswordSignIn,
} from '../../../utils/validationUtils';
import { useTranslation } from 'react-i18next';
import eyeOffIcon from '../../../assets/icons/eye_off.svg';
import eyeOnIcon from '../../../assets/icons/eye_on.svg';
import { Login } from '../../../services/Api/userService';

const SignUpComponent: FunctionComponent = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [suspendedMessage, setSuspendedMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, setCookie] = useCookies([
    'user',
    'super_user',
    'token',
    'role',
    'settings',
  ]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailError(!validateEmail(newEmail));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordError(!validatePasswordSignIn(newPassword));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const isFormValid =
    !emailError && !passwordError && email !== '' && password !== '';

  const handleSignIn = async () => {
    if (isFormValid) {
      setIsLoading(true);
      setTimeout(async () => {
        try {
          const response = await Login({ email, password });

          if (response.data.user.company_status === 'SUSPENDED') {
            setIsLoading(false);
            setSuspendedMessage(
              t(
                'Your account has been suspended. Please contact support (support@47660588.hubspot-inbox.com) for assistance.'
              )
            );
            return;
          }

          setCookie('token', response.data.token, { path: '/' });

          setCookie('user', response.data.user, { path: '/' });
          setCookie('super_user', response.data.user.super_user, { path: '/' });
          setCookie('role', response.data.user.role, { path: '/' });
          setCookie('settings', response.data.settings, { path: '/' });

          if (response.data.user.super_user) {
            navigate('/admin/clients');
          } else {
            navigate('/');
          }
        } catch (error) {
          setIsLoading(false);
          console.error('Login failed:', error);
          setLoginError(true);
          setTimeout(async () => {
            setLoginError(false);
          }, 3000);
        }
      }, 1000);
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };
  const handleContactUsClick = () => {
    const body = encodeURIComponent(
      `Hi GritCargo Team,

I am interested in discussing potential business opportunities with GritCargo and would love to learn more about your services. Please contact me at your earliest convenience.

Best regards,
[User Name]
[Company Name]
[Contact Information]`
    );

    window.location.href = `mailto:support@47660588.hubspot-inbox.com?subject=Business Inquiry - Request for Membership&body=${body}`;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '21.5vw',
        height: '63.03vh',
        borderRadius: '16.224px',
        border: '2px solid',
        borderColor: 'var(--Primary-Lime, #B4EE2B)',
        backgroundColor: 'var(--white-100, #FFF)',
        padding: '4.69vh 3.70vw 6.56vh 3.70vw',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          paddingTop: '1.85vh',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1.87vh',
          alignSelf: 'stretch',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1.85vh',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '13.06vw',
              height: '5.42vh',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
            }}
          >
            <img src={gritLogo} style={{ width: '100%' }} alt="grit logo" />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1.85vh',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '0.84vw',
                fontWeight: 600,
                lineHeight: '2.22vh',
                textAlign: 'center',
                color: '#000',
              }}
            >
              {t('In partnership with:')}
            </Typography>

            <img
              src={MegaFortress}
              alt="MegaFortress Logo"
              style={{
                width: '4.19vw',
                height: '8.32vh',
              }}
            />
          </Box>
        </Box>

        {!suspendedMessage && (
          <>
            <Box
              sx={{
                display: 'flex',
                paddingTop: '2.25vh',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '0.56vh',
                alignSelf: 'stretch',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '1.67vw',
                  fontWeight: 600,
                  lineHeight: '3.70vh',
                  textAlign: 'center',
                  color: '#000',
                  alignSelf: 'stretch',
                }}
              >
                {t('Sign In')}
              </Typography>

              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '0.94vw',
                  fontWeight: 400,
                  lineHeight: '2.59vh',
                  textAlign: 'center',
                  color: '#838383',
                  alignSelf: 'stretch',
                }}
              >
                {t('Use your Email to sign in')}
              </Typography>
            </Box>
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            height: '1.87vh',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
          }}
        ></Box>
      </Box>

      {!suspendedMessage && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '1.87vh',
              alignSelf: 'stretch',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '0.56vh',
              }}
            >
              <TextField
                placeholder={t('Email')}
                fullWidth
                value={email}
                onChange={handleEmailChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSignIn();
                  }
                }}
                error={emailError}
                helperText={emailError ? t('Must be a valid email') : ''}
                slotProps={{
                  formHelperText: {
                    sx: { textAlign: 'left', marginLeft: '0px' },
                  },
                }}
                sx={{
                  'backgroundColor': '#FFFFFF',
                  'borderRadius': '0.42vw',
                  'marginBottom': '1.30vh',
                  '& .MuiOutlinedInput-root': {
                    'borderColor': '#C7C7C7',
                    '& fieldset': {
                      borderColor: '#C7C7C7',
                    },
                    '&:hover fieldset': {
                      borderColor: '#C7C7C7',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#C7C7C7',
                    },
                  },
                  '& .MuiInputBase-input': {
                    padding: '0.73vw',
                    color: '#000',
                    fontFamily: 'Open Sans',
                    fontSize: '0.84vw',
                    fontWeight: 400,
                    lineHeight: '1.27vw',
                    textAlign: 'left',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#C7C7C7',
                  },
                  '& .MuiFormLabel-root.Mui-focused': {
                    color: '#C7C7C7',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: '#C7C7C7',
                    fontFamily: 'Open Sans',
                    fontSize: '0.84vw',
                    fontWeight: 400,
                    lineHeight: '1.27vw',
                    textAlign: 'left',
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '0.56vh',
                alignSelf: 'stretch',
              }}
            >
              <TextField
                placeholder={t('Password')}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                value={password}
                onChange={handlePasswordChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSignIn();
                  }
                }}
                error={loginError}
                helperText={loginError ? t('Invalid login credentials') : ''}
                slotProps={{
                  formHelperText: {
                    sx: { textAlign: 'left', marginLeft: '0px' },
                  },
                  input: {
                    endAdornment: (
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        <img
                          src={showPassword ? eyeOnIcon : eyeOffIcon}
                          alt="Toggle password visibility"
                        />
                      </IconButton>
                    ),
                  },
                }}
                sx={{
                  'backgroundColor': '#FFFFFF',
                  'borderRadius': '0.42vw',
                  '& .MuiOutlinedInput-root': {
                    'borderColor': '#C7C7C7',
                    '& fieldset': {
                      borderColor: '#C7C7C7',
                    },
                    '&:hover fieldset': {
                      borderColor: '#C7C7C7',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#C7C7C7',
                    },
                  },
                  '& .MuiInputBase-input': {
                    padding: '0.73vw',
                    color: '#000',
                    fontFamily: 'Open Sans',
                    fontSize: '0.84vw',
                    fontWeight: 400,
                    lineHeight: '1.27vw',
                    textAlign: 'left',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#C7C7C7',
                  },
                  '& .MuiFormLabel-root.Mui-focused': {
                    color: '#C7C7C7',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: '#C7C7C7',
                    fontFamily: 'Open Sans',
                    fontSize: '0.84vw',
                    fontWeight: 400,
                    lineHeight: '1.27vw',
                    textAlign: 'left',
                  },
                }}
              />

              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '0.74vw',
                  fontWeight: 400,
                  lineHeight: '142.857%',
                  textAlign: 'right',
                  color: '#86BE02',
                  cursor: 'pointer',
                  alignSelf: 'end',
                }}
                onClick={handleForgotPassword}
              >
                {t('Forgot Password')}?
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              height: '8.62vh',
              paddingTop: '3.00vh',
              flexDirection: 'column',
              alignItems: 'flex-end',
              alignSelf: 'stretch',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                paddingBottom: '2.25vh',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '1.12vh',
                alignSelf: 'stretch',
              }}
            >
              <Button
                sx={{
                  backgroundColor: isFormValid ? '#000000' : '#EEEEEE',
                  color: isFormValid ? '#FFFFFF' : '#838383',
                  padding: '1.48vh 1.26vw',
                  borderRadius: '16px',
                  width: '100%',
                  fontSize: '0.94vw',
                  lineHeight: '2.59vh',
                  fontFamily: "'Open Sans', sans-serif",
                  textAlign: 'center',
                  textTransform: 'none',
                  display: 'inline-block',
                  position: 'relative',
                  cursor: isFormValid ? 'pointer' : 'not-allowed',
                }}
                disabled={!isFormValid}
                onClick={handleSignIn}
              >
                {t('Sign In')}
              </Button>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: '0.73vw',
              color: '#000000',
              textAlign: 'center',
              lineHeight: '1.85vh',
              fontFamily: 'Open Sans',
              fontWeight: 600,
              alignSelf: 'stretch',
            }}
          >
            {t('Need to sign up')}?{' '}
            <Typography
              component="span"
              sx={{
                fontSize: '0.73vw',
                color: '#86BE02',
                cursor: 'pointer',
                fontFamily: 'Open Sans',
                fontWeight: 600,
                lineHeight: '1.85vh',
              }}
              onClick={handleContactUsClick}
            >
              {t('Contact our sale team')}
            </Typography>
          </Typography>

          {isLoading && (
            <>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  zIndex: 1,
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress sx={{ color: '#B4EE2B' }} size="4.18vw" />
              </Box>
            </>
          )}
        </>
      )}

      {suspendedMessage && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'var(--Background-BG5, #F5F5F5)',
              borderRadius: 'var(--16, 16px)',
              paddingTop: '2.22vh',
              paddingBottom: '2.22vh',
              paddingRight: '1.25vw',
              paddingLeft: '1.25vw',
              gap: '0.93vh',
              alignSelf: 'stretch',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '6.084px',
                alignSelf: 'stretch',
              }}
            >
              <Typography
                sx={{
                  alignSelf: 'stretch',
                  color: 'var(--black-100, #000)',
                  textAlign: 'center',
                  fontFeatureSettings: "'ss01' on, 'cv01' on",
                  fontFamily: '"Open Sans", sans-serif',
                  fontSize: '1.25vw',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '2.96vh',
                }}
              >
                {t('Account Suspended')}
              </Typography>

              <Typography
                sx={{
                  alignSelf: 'stretch',
                  color: 'var(--black-40, #838383)',
                  textAlign: 'center',
                  fontFeatureSettings: "'ss01' on, 'cv01' on",
                  fontFamily: '"Open Sans", sans-serif',
                  fontSize: '0.729vw',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '1.852vh',
                }}
              >
                {t(
                  'Your account has been suspended. If the problem persists, please'
                )}{' '}
                <Typography
                  component="span"
                  sx={{
                    color: 'var(--secondary-lime-100, #86BE02)',
                    fontFeatureSettings: "'ss01' on, 'cv01' on",
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: '0.729vw',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '1.852vh',
                    cursor: 'pointer',
                  }}
                  onClick={handleContactUsClick}
                >
                  {t('contact support.')}
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              marginTop: '1.88vh',
              alignSelf: 'stretch',
              color: 'var(--black-100, #000)',
              textAlign: 'center',
              fontFeatureSettings: "'ss01' on, 'cv01' on",
              fontFamily: '"Open Sans", sans-serif',
              fontSize: '0.73vw',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '1.85vh',
            }}
          >
            {t('Go back to')}{' '}
            <Typography
              component="span"
              sx={{
                color: 'var(--secondary-lime-100, #86BE02)',
                fontFeatureSettings: "'ss01' on, 'cv01' on",
                fontFamily: '"Open Sans", sans-serif',
                fontSize: '0.73vw',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '1.85vh',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSuspendedMessage('');
              }}
            >
              {t('Sign In')}
            </Typography>
          </Typography>
        </>
      )}
    </Box>
  );
};

export default SignUpComponent;
