import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  InputAdornment,
  Autocomplete,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/magnifying-glass.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import modalTheme from '../../../../theme/modalTheme';
import { CompanyDriversResponse } from '../../../../interfaces/Driver/Response/companyDrivers';
import { CompanyLocationsResponse } from '../../../../interfaces/Location/Response/companyLocations';
import { useTranslation } from 'react-i18next';

interface AddNewTripModalProps {
  open: boolean;
  onClose: () => void;
  formValues: { location: string; driver: string };
  setFormValues: React.Dispatch<
    React.SetStateAction<{ location: string; driver: string }>
  >;
  locations: CompanyLocationsResponse[];
  drivers: CompanyDriversResponse[];
  onSubmit: () => void;
  handleAddLocationOpen: () => void;
}

const AddNewTripModal: React.FC<AddNewTripModalProps> = ({
  open,
  onClose,
  formValues,
  setFormValues,
  drivers,
  locations,
  onSubmit,
  handleAddLocationOpen,
}) => {
  useEffect(() => {
    if (!open) {
      setFormValues({ location: '', driver: '' });
    }
  }, [open, setFormValues]);

  const isFormValid = formValues.location.trim() !== '';
  const { t } = useTranslation();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFormValid && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFormValid, onSubmit, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{ '& .MuiDialog-paper': { width: '480px' } }}
      >
        <DialogTitle>Add New Trip</DialogTitle>
        <Typography variant="subtitle1">
          Adding a new trip will add it to the top of the vehicle&apos;s current
          route.{' '}
        </Typography>
        <DialogContent>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Location')}
          </Typography>
          <Autocomplete
            options={locations}
            forcePopupIcon={false}
            getOptionLabel={(option) => option.name}
            value={locations.find((v) => v.id === formValues.location) || null}
            onChange={(event, newValue) => {
              setFormValues({ ...formValues, location: newValue?.id || '' });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Location"
                fullWidth
                sx={{ mb: 2, backgroundColor: 'white' }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        style={{
                          height: '14px',
                          width: '15px',
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      <Button
                        onClick={handleAddLocationOpen}
                        sx={{
                          'minWidth': 'auto',
                          'padding': 0,
                          'borderLeft': '1px solid #c7c7c7',
                          'height': '44px',
                          'width': '36px',
                          'borderRadius': '0',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <PlusIcon
                          style={{
                            height: '14px',
                            paddingLeft: '5px',
                            width: '14px',
                          }}
                        />
                      </Button>
                    </>
                  ),
                }}
              />
            )}
          />
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            Driver
          </Typography>
          <Autocomplete
            options={drivers}
            getOptionLabel={(option) => option.full_name}
            value={drivers.find((v) => v.id === formValues.driver) || null}
            onChange={(event, newValue) => {
              setFormValues({ ...formValues, driver: newValue?.id || '' });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Driver"
                fullWidth
                sx={{ mb: 2, backgroundColor: 'white' }}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{
                            height: '14px',
                            width: '15px',
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!(formValues.location.trim() !== '')}
            onClick={onSubmit}
            color="primary"
          >
            Assign to Vehicle
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddNewTripModal;
