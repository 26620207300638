import { ThemeProvider } from '@emotion/react';
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Autocomplete,
  TextField,
  InputAdornment,
  DialogActions,
  Button,
} from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import modalTheme from '../../../../theme/modalTheme';
import { GetAllClientsResponse } from '../../../../interfaces/Company/Response/getAllClients';
import { GetAllClientsRequest } from '../../../../interfaces/Company/Request/getAllClients';
import {
  assignMassSeals,
  getAllClients,
} from '../../../../services/Api/apiAdmin';
import { useCookies } from 'react-cookie';
import SnackbarComponent from '../../../../components/Snackbar/SnackbarComponent';

import { ReactComponent as SearchIcon } from '../../../../assets/icons/magnifying-glass.svg';

interface AssignDeviceProps {
  open: boolean;
  onClose: () => void;
  selectedSeal: string;
  onSuccess: () => void;
}

const AssignDevice: React.FC<AssignDeviceProps> = ({
  open,
  onClose,
  selectedSeal,
  onSuccess,
}) => {
  const [cookies] = useCookies(['user']);
  const [allClients, setAllClients] = useState<GetAllClientsResponse[]>([]);

  const [formValues, setFormValues] = useState({
    company_id: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('error');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const payload: GetAllClientsRequest = {
          admin_email: cookies.user.email,
          search_term: '',
          page: -1,
          refetch: true,
        };
        const data = await getAllClients(payload);
        setAllClients(data.clients);
      } catch {
        setSnackbarMessage('Error fetching client list.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchClients();
  }, [cookies.user.email]);

  const handleMassAssignSeal = () => {
    const assignSeals = async () => {
      const payload = {
        admin_email: cookies.user.email,
        company_id: formValues.company_id,
        seals: [selectedSeal],
      };

      try {
        const returnData = await assignMassSeals(payload);

        if (returnData.statusCode === 200) {
          onSuccess();
          onClose();
        }

        setSnackbarMessage('Successfully assigned seal');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch {
        setSnackbarMessage('Error assigning seal');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };
    assignSeals();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && formValues.company_id.trim() !== '') {
      event.preventDefault();
    }
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('Assign Device to Client')}</DialogTitle>
        <Typography variant="subtitle1">
          {t(
            'The selected iMSeal device will be assigned to the selected client. Once the client has activated the devices this action is permanent.'
          )}
        </Typography>
        <DialogContent>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Select Client')}
          </Typography>
          <Autocomplete
            options={allClients}
            getOptionLabel={(option) => option.client}
            value={
              allClients.find((v) => v.id === formValues.company_id) || null
            }
            onChange={(event, newValue) => {
              setFormValues({
                company_id: newValue?.id || '',
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('Company Name')}
                fullWidth
                onKeyDown={handleKeyDown}
                sx={{ mb: 2, backgroundColor: 'white' }}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{
                            height: '14px',
                            width: '15px',
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={formValues.company_id === ' '}
            onClick={handleMassAssignSeal}
            color="primary"
          >
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
    </ThemeProvider>
  );
};

export default AssignDevice;
